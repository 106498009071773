import React, { useState } from "react";
import { connect } from "react-redux";
import { AirlineCreateRoute, AirlineEditRoute } from "../../../routesConstants";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Link } from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Process from "../../../../components/process";
import CreateAirlineByIsoCode from "../components/CreateAirlineByIsoCode";
import {Helmet} from "react-helmet";

const AirlineList = props => {
  const columns = [
    {
      Header: "Название",
      accessor: "name",
      Cell: ({ value, row }) => (
        <Link to={AirlineEditRoute + row.original.id}>{value}</Link>
      )
    },
    {
      Header: "Iata 2",
      accessor: "iso2"
    },
    {
      Header: "Iata код",
      accessor: "code"
    },
    {
      Header: "Действия",
      Cell: ({ value, row }) => (
        <Link
          className={"btn btn-sm btn-clean btn-icon btn-icon-md"}
          to={AirlineEditRoute + row.original.id}
        >
          <i className={"la la-edit"} />
        </Link>
      )
    }
  ];

  const [query, setQuery] = useState("");
  const [tabIndex, setTabIndex] = useState("home");
  return (
    <>
      <Helmet>
        <title>Авиакомпании</title>
      </Helmet>
      <Tabs
        variant="pills"
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => setTabIndex(key)}
      >
        <Tab eventKey="home" title="Список">
          <div className={"kt-grid__item"}>
            <Portlet>
              <PortletHeader
                title={"Список авиакомпаний"}
                toolbar={
                  <PortletHeaderToolbar>
                    <PortletHeaderSearch
                      value={query}
                      cb={v => {
                        setQuery(v);
                      }}
                    />
                    <Link
                      to={AirlineCreateRoute}
                      className="btn btn-sm btn-brand btn-elevate btn-icon-sm"
                    >
                      <i className="la la-plus" />
                      Добавить
                    </Link>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <DataTable
                  columns={columns}
                  search={query}
                  url={`/api/air/airlines`}
                />
              </PortletBody>
            </Portlet>
          </div>
        </Tab>

        <Tab eventKey="profile" title="Действия" className="tab-no-background">
          <Row>
            <Col md={6}>
              <Process
                desc={"Синхронизует с flightStats"}
                title={"Синхронизация авиакомпаний"}
                link={"/api/air/airlines/syncAirlines"}
              />
            </Col>
            <Col md={6}>
              <Process
                desc={"Импортирует данные с старого сайта (классы и кода)"}
                title={"Импорт авиа"}
                link={"/api/air/airlines/addOldAirlinesFromJson"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CreateAirlineByIsoCode/>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </>
  );
};

export default connect(null)(AirlineList);
