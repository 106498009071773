import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../../components/Portlet";
import LoadingButton from "../../../base/components/loadingButton";
import React, {useState} from "react";
import {Col} from "reactstrap";
import {useForm} from "react-hook-form";
import {Form, Row} from "react-bootstrap";
import CheckTypeSelect from "../../../parser/parserCheck/components/checkTypeSelect";
import FormHookInput from "../../airBooking/components/formHookInput";
import axios from "../../../../helpers/axios";
import {useNavigate} from "react-router";
import {AirplaneListRoute} from "../../../routesConstants";
import {Helmet} from "react-helmet";

const AirplaneCreatePage = ()=>{
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { register,
    handleSubmit,
    control,
    formState: {errors}} = useForm()
  const onSubmit = (data) =>{
    setLoading(true)
    axios
      .put('/api/air/airplanes/', data)
      .then(()=>{
      navigate(AirplaneListRoute)
    }).catch(e=>{
      alert('Что-то пошло не так')
      console.log('err', e)
    }).finally(()=>setLoading(false))
  }
  return<>
    <Helmet>
      <title>Самолет</title>
    </Helmet>
    <Col xs={6}>
    <Portlet>
      <PortletHeader title={'Новый самолет'}/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PortletBody>
          <Row className={"flex-d align-items-center"}>
            <Form.Label column sm="3">
              Официальное название
            </Form.Label>
            <Col sm={9}>
              <FormHookInput
                register={register("originalName", {
                  required: true,
                  minLength: 3
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Form.Label column sm="3">
              Iata Code
            </Form.Label>
            <Col sm={9}>
              <FormHookInput
                register={register("iataCode", {
                  required: true,
                  minLength: 3,
                  maxLength:3
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Form.Label column sm="3">
              Название
            </Form.Label>
            <Col sm={9}>
              <FormHookInput
                register={register("name", {
                  required: true,
                  minLength: 3,
                })}
                errors={errors}
              />
            </Col>
          </Row>
        </PortletBody>
        <PortletFooter>
          <LoadingButton loading={loading} title={'Сохранить'}/>
        </PortletFooter>
      </form>
    </Portlet>
  </Col>
  </>
}
export default AirplaneCreatePage
