import React, {useState} from 'react';
import {Table} from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";

const BadTransItem = ({name, arr,type}) => {
  const renderRow = (r) =>{
    if(type==='transactions'){
      return <>
        <td style={{paddingLeft: '2rem'}}>{r.date}</td>
        <td>{r.total}</td>
        <td>{r.counterparty || "Неопределено"}</td>
        <td>{r.comment}</td>
      </>
    } else {
      return <>
      <td style={{paddingLeft: '2rem'}}>{r.issueDate.slice(0, 10)}</td>
        <td>{r.types.map(t => <React.Fragment>{t}<br/></React.Fragment>)}</td>
        <td>{numberFormat(r.total)}</td>
      </>
    }
  }
  const [show, setShow] = useState(false)
  return (
    <div>
      <div className={'d-flex justify-content-between align-items-center  serviceCard'} onClick={()=>setShow(!show)}>
        <div><b>{name}</b>. Проблемных: {arr.length}</div>
        <i className={`fa fa-chevron-${!show  ? 'up' : 'down'}`}/>
      </div>
      {show && <div>
        <Table bordered>
          <tbody>
          {arr.map((r, i) => {
            return <tr key={i}>
              {renderRow(r)}
            </tr>
          })}
          </tbody>
        </Table>
      </div>}
    </div>

  );
};

export default BadTransItem;
