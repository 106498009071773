import React, {useEffect, useState} from 'react';
import ApiService from "../../../services/apiService";
import {ReviseItemListRoute} from "../../routesConstants";
import axios from "../../../helpers/axios";
import {useParams} from "react-router-dom";
import sayError from "../../../helpers/sayError";
import {noDispHeader} from "../../../helpers/errorTranslate";

const ReSaveReportButton = ({newFile,oldFile, data, id, comment}) => {
  console.log(data)
  const [uploadStatus, setUploadStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(newFile){
      setUploadStatus([newFile]);
    }
  }, [newFile]);
  function uploadData() {
    const req = {
      dataJson: JSON.stringify(data),
      comment
    };
    if(uploadStatus.length){
      req.newDocuments = uploadStatus.map(u => {
        return {
          name: u.name,
          url: u.fileUrl,
          originalName: u.file.name
        };
      })
    }else{
      req.newDocuments=[]
      req.oldDocument= oldFile ? oldFile.id: undefined
    }
    console.log("req", req);
    axios.patch(`/api/racoon/revise/item/${id}`, req)
      .then(()=>{
        window.location.reload()
      })
      .finally(()=>setLoading(false))
  }
  const upload = async () => {
    setLoading(true);
    if(!newFile){
      uploadData()
    }else{
      for (var file of uploadStatus) {
        const data = new FormData();
        data.append("file", file.file);
        data.append("folder", 'revises');
        const resp = await axios.post("/api/misc/cloudStorageUpload", data,{headers: {
            'Content-Type':"application/x-www-form-urlencoded"}});
        console.log("resp", resp);
        file.uploaded = true;
        file.fileUrl = resp.data.fileUrl;
        setUploadStatus([...uploadStatus]);
      }
    }

  };
  useEffect(() => {
    if (
      uploadStatus &&
      uploadStatus.length &&
      uploadStatus.every(u => u?.uploaded)
    ) {
      uploadData();
    }
  }, [uploadStatus]);
  return (
    <button className={"btn btn-outline-success ml-2 "}
            onClick={()=>upload()}
    >
      {loading ? 'Загрузка...' :'Сохранить изменения'}
    </button>
  );
};

export default ReSaveReportButton;
