import React, {useState} from "react";
import {TimeTableTemplateCreateRoute, TimeTableTemplateEditRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import {Link} from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import {Helmet} from "react-helmet";

const TimeTableTemplateList = (props)=>{
  const columns = [
    {
      Header: 'Название',
      accessor: 'name',
      Cell: ({value, row}) => <Link to={TimeTableTemplateEditRoute + row.original._id } >{value}</Link>
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (<Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={TimeTableTemplateEditRoute + row.original._id}><i className={"la la-edit"}/></Link>)
    }
  ]

  const [query, setQuery] = useState("");
  return <>
    <Helmet>
      <title>Шаблоны</title>
    </Helmet>
    <div className={"kt-grid__item"}>
      <Portlet>
        <PortletHeader
          title={"Список шаблонов"}
          toolbar={
            <PortletHeaderToolbar>
              <PortletHeaderSearch value={query} cb={(v)=>{setQuery(v)}}/>
              <Link to={TimeTableTemplateCreateRoute} className="btn btn-sm btn-brand btn-elevate btn-icon-sm">
                <i className="la la-plus"/>
                Добавить
              </Link>
            </PortletHeaderToolbar>}
        />
        <PortletBody>
          <DataTable columns={columns} search={query} url={`/api/parsers/timeTable`}/>
        </PortletBody>
      </Portlet>
    </div>
  </>
}


export default TimeTableTemplateList
