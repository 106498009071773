import { useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetItem, UpdateItem } from "../../../../redux/actions/base";
import Loader from "../../../../components/loader";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import {Col, Form, Row} from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import LoadingButton from "../../../base/components/loadingButton";
import { ParsersCheckListRoute } from "../../../routesConstants";
import axios from "../../../../helpers/axios";
import StandartInputsChecks from "../components/standartInputsChecks";
import Breadcrumbs from "../../../../components/breadcrumbs";
import CheckButton from "../components/checkButton";
import {Table} from "reactstrap";
import ResultTable from "../../../landing/components/resultTable";
import ReactJson from "react-json-view";
import {Helmet} from "react-helmet";

const ParserCheckEdit = props => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [resultCheck, setResultCheck] = useState(null)
  const formController = useForm({
    defaultValues: {
      checks: []
    }
  });
  const {
    register,
    handleSubmit,
    formState: { errors , isValid},
    getValues,
    control,
    watch,
    reset
  } = formController;
  const fildParam = useFieldArray({
    control,
    name: "checks"
  });
  useEffect(() => {
    axios
      .get(`/api/parseCheck/${id}`)
      .then(({ data }) => {
        console.log("data", data);
        setItem(data.formatedData);
        setLoading(false);
        reset({ checks: data.formatedData.checks });
      })
      .catch(e => console.log(e));
  }, []);


  const onSubmit = ({ checks, isHandled }) => {
    setLoading(true);
    const send = {
      parseType: item.parseType,
      parseText: item.parseText,
      isHandled,
      message: item.message,
      checks: checks
    };
    axios
      .patch(`/api/parseCheck/${id}`, send)
      .then(() => {
        setLoading(false);
        navigate(ParsersCheckListRoute);
      })
      .catch(e => console.log("err", e));
  };
  if (loading) {
    return <Loader />;
  }
  console.log('resultCheck', resultCheck)
  const removeCheck = () => {
    if (window.confirm("Вы уверены что хотите удалить?")) {
      setLoading(true);
      axios
        .delete(`/api/parseCheck/${id}`)
        .then(() => {
          setLoading(false);
          navigate(ParsersCheckListRoute);
        })
        .catch(e => console.log("err", e));
    }
  };
  const crumbs = [
    { title: "Список ошибок", path: ParsersCheckListRoute },
    { title: "Редактирование ошибки" }
  ];
  return (
    <>
      <Helmet>
        <title>Ошибки</title>
      </Helmet>
      <Breadcrumbs data={crumbs} />
      <Row>
        <Col xs={6}>
          <Portlet>
            <PortletHeader
              title={"Ошибка"}
              toolbar={
                <PortletHeaderToolbar>
                  <i
                    className={"fa fa-trash"}
                    style={{ cursor: "pointer" }}
                    onClick={() => removeCheck()}
                  />
                </PortletHeaderToolbar>
              }
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <PortletBody>
                <p>
                  Тип - {item.parseType} <br />
                  Создал - {item.createdBy.email} <br />
                  Создано - {item.createdDate} <br />
                  {item.lastCheckedDate.length > 0
                    ? `Последняя проверка - ${item.lastCheckedDate}`
                    : "Еще не проверялось"}
                  <br />
                  <br />
                  Сообщение - {item.message}
                </p>
                <Form.Check
                  type={"checkbox"}
                  label={"Обработано"}
                  {...register(`isHandled`)}
                  defaultChecked={item.isHandled}
                />
                <br/>
                <StandartInputsChecks
                  formController={formController}
                  fildParam={fildParam}
                />
              </PortletBody>
              <PortletFooter>
                <div  style={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
                  <LoadingButton title={"Сохранить"} loading={loading} />
                  <div className={'d-flex gap-4 align-items-center' }>
                    <Form.Check
                      type={"checkbox"}
                      label={"Дата создания"}
                      onChange={(event)=>{
                        setItem(item=>({...item, dateCheck:event.target.checked}))
                      }}
                      defaultChecked={false}
                    />
                    <CheckButton isValid={isValid}
                                 setResultCheck={setResultCheck}
                                 item={{...item,...getValues()}}/>
                  </div>

                </div>
                {!isValid && <div style={{textAlign:'end'}}>Заполните форму</div>}
              </PortletFooter>
            </form>
          </Portlet>
          {resultCheck && <ResultTable resultCheck={resultCheck}/> }
          {resultCheck?.json && <Portlet>
            <PortletBody>
              <ReactJson
                src = {JSON.parse(resultCheck?.json)}
                name = {null}
                enableClipboard = {false}
                displayDataTypes = {false}
                displayObjectSize = {false}
                displayArrayKey = {false}
                iconStyle = "circle"
              ></ReactJson>
            </PortletBody>
          </Portlet>}

        </Col>
        <Col xs={6}>
          <Portlet>
            <PortletHeader title={"Текст парса"} />
            <PortletBody>
              <div>
                {item.parseText.split("\n").map((t, i) => {
                  return <p key={i}>{t}</p>;
                })}
              </div>
            </PortletBody>
          </Portlet>
        </Col>
      </Row>
    </>
  );
};
export default connect(null, { GetItem, UpdateItem })(ParserCheckEdit);
