import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import moment from "moment";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import ApiSelect from "../../base/components/forms/apiSelect";
import {useLocation} from "react-router-dom";
import _ from 'lodash'
import {Form} from "react-bootstrap";

const SearchForm = ({cb, one, url, grouped}) => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false)


  const formController = useForm()
  const {handleSubmit,
    register,
    control,
    setValue} = formController
  useEffect(() => {
    if(search){
      setValue('counterparty',new URLSearchParams(search).get('id'))
      setValue('start',new URLSearchParams(search).get('start'))
      setValue('end',new URLSearchParams(search).get('end'))
    }
  }, []);
  const onSubmit = (dataF) => {
    setLoading(true)
    console.log('submit answer', dataF)
    dataF.groupBySupplier = true
      axios.post(url,dataF).then((resp)=>{
        console.log('resp.request',resp)
        cb(resp.data,dataF)
      })
      .finally(()=>setLoading(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-center gap-2'}>
      <Form.Label className={'d-flex align-items-center gap-2'}>
        <input
          defaultChecked={new URLSearchParams(search).get('unComp')=== 'true'}
          {...register(`uncompleted`)}
          type={"checkbox"}
        />
        Непроведенные
      </Form.Label>
      <CustomDatePicker formController={formController}
                        label={"Дата c"}
                        value={moment().subtract(7, 'days').format('DD.MM.YYYY')}
                        name={`start`}
                        rules={{required: true}}/>
      <CustomDatePicker formController={formController}
                        label={"Дата по"}
                        value={moment().format('DD.MM.YYYY')}
                        name={`end`}
                        rules={{required: true}}/>
      {one && <div style={{width:200}} ><Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <ApiSelect
            value={value}
            label={'Контрагент'}
            url={"/api/counterparty/searchInput"}
            cb={newVal => onChange(newVal)}
          />
        )}
        name={`counterparty`}
      /></div>}
      <LoadingButton loading={loading} title={'Получить'}/>

    </form>
  );
};

export default SearchForm;
