import React, {useState, useEffect, Fragment} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import TemplateForm from "../components/templateForm";
import {connect} from "react-redux";
import {CreateItem, StartProcess} from "../../../../redux/actions/base";
import {Row, Col, Table, FormControl, Form, Button} from 'react-bootstrap'
import _ from 'lodash'
import parser from "../helpers/parser";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ParserSendMess from "../../components/parserSendMess";
import {Helmet} from "react-helmet";


const TimeTableParsePage = ({StartProcess}) =>{
  const [language, setLanguage] = useState('ru');
  const [parseResult, setParseResult] = useState();
  const [showResult, setShowResult] = useState(false);
  const [text, setText] = useState('');
  const [templates, setTemplates] = useState();
  const [transformedBooking, setTransformedBooking] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  useEffect(()=>{
    if(!templates){
      setLoading(true);
      StartProcess('/api/parsers/timeTable/getTemplates', async ({data})=>{
        setTemplates(data.items);
        selectTemplate(data.items[0]._id, data.items);
        setLoading(false);
      })
    }
  }, [])
  const selectTemplate = (id, templates) =>{
    const selectedTemplate = _.find(templates, {_id: id});
    setSelectedTemplate(selectedTemplate);
    if(parseResult){
      transformBooking(parseResult,selectedTemplate)
    }
  }
  const transformBooking = () =>{
    setTransformedBooking(parser(selectedTemplate, language, parseResult, params ))
  }

  const [params, setParams] = useState({
    comment: ''
  })

  const [loading, setLoading] = useState(false);
  const uploadText = () =>{
    setLoading(true);
    StartProcess('/api/parsers/timeTable/parse', ({data})=>{
      if(data.success){
        setParseResult(data.result);
        setShowResult(true)
      }
      setLoading(false);
    }, {text:text})
  }
  useEffect(()=>{
    if(parseResult){
      transformBooking()
    }
  },[selectedTemplate, language, parseResult])
  return <>
    <Helmet>
      <title>Парс распиания</title>
    </Helmet>
    <Row>
      <Col md={6}>
      </Col>
      <Col md={6}>
        <Portlet>
          <PortletHeader title={'Расписание'}
                         toolbar={<PortletHeaderToolbar>
                           {showResult?
                             <>

                               <Button onClick={()=>setShowResult(false)}>
                                 Форма
                               </Button>
                             </>
                             :
                             <>
                               <ParserSendMess parseText={text} type={'timeTable'}/>
                               <Button disabled={text.length === 0}
                                       className={loading?"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":null}
                                       onClick={()=>uploadText(text)}>
                                 Загрузить
                               </Button>
                             </>

                           }

                         </PortletHeaderToolbar>}
          />
          <PortletBody>
            {showResult?
              <div>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Язык</Form.Label>
                  <Form.Control value={language} as="select" onChange={(item)=>{setLanguage(item.target.value)}}>
                    <option value={'ru'}>Русский</option>
                    <option value={'en'}>Английский</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Шаблон</Form.Label>
                  <Form.Control value={selectedTemplate._id} as="select" onChange={(item)=>{selectTemplate(item.target.value, templates)}}>
                    {templates && templates.map((template, i) => {
                      return <option key={i} value={template._id}>{ template.name }</option>
                    })}
                  </Form.Control>
                </Form.Group>
                {transformedBooking && transformedBooking.split('\n').map((item, key) => {
                  return <Fragment key={key}>{item}<br/></Fragment>
                })}
                <CopyToClipboard text={transformedBooking}>
                  <Button>Копировать</Button>
                </CopyToClipboard>
              </div>
              :
              <div>
                <Row>
                  <Col>
                    <FormControl
                      name={'comment'}
                      value={params.comment}
                      placeholder={'Комментарий'}
                      onChange={(val) => setParams({...params, comment:val.target.value })}
                    />
                    <br/>
                    <Form.Control
                      name={'text'}
                      value={text}
                      onChange={(val)=>setText(val.target.value)}
                      as={'textarea'}
                      placeholder={'Расписание'}
                      rows={20}
                    />
                  </Col>
                </Row>
              </div>
            }

          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  </>
}

export default connect(null, {StartProcess})(TimeTableParsePage)
