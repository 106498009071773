import React, {useState} from 'react';
import _ from "lodash";
import {Table} from "react-bootstrap";
import OneConterpartyTable from "./oneConterpartyTable";
import numberFormat from "../../../helpers/numberFormat";
import {Link} from "react-router-dom";
import MoreFunctionButton from "./moreFunctionButton";

const baseObj = {
  "operation": "",
  "pax": "",
  "BSO": "",
  "route": "",
  "total": 0,
  "flightDate": ""
}
const SaleTable = ({editbleArray,setEditableArray,cb, showCol, setShowCol}) => {
  const style = {"display":"none"}

  const editing = (index, value,name)=>{
    const all = structuredClone(editbleArray)
    if(name==='total' ||name==='krsBuyer'||name==='krsKayar'){
      all.tableData[index][name] = +value.replace(/\s/g, '').replace(',','.')
    } else if(name==='counterparty' || name==='date' ){
      all.header[name] = value
    }
    else{
      all.tableData[index][name] = value
    }
    setEditableArray(all)
  }

  const removeRow = (index) =>{
    if(window.confirm(`Вы уверены что хотите удалить ${editbleArray.tableData[index].operation}`)){
      const arr = editbleArray.tableData.slice()
      arr.splice(index, 1)
      setEditableArray({...editbleArray,tableData:arr})
    }
  }
  const addRow = (index, direction, tIndex) =>{
    const arr = editbleArray.tableData.slice()
    if(direction === 'up'){
      arr.splice(index, 0, baseObj)
    }else{
      arr.splice(index+1, 0, baseObj)
    }
    setEditableArray({...editbleArray,tableData:arr})
  }
  const colSpan = () =>{
    if(!showCol.name && !showCol.krs || !showCol.name && showCol.krs){
      return 6
    }else{
      return  5
    }
  }
  console.log("_.sumBy(editbleArray.tableData.krsBuyer)",editbleArray.tableData)

  return (
    <div className={'text-black mb-2'}
         // id={getAllLenght()}
    >
      <h2 className={'text-center mt-4 mb-4'}>Краткий реестр продаж по покупателю</h2><br/>
      <div>
        <div className={'d-flex'}>
          Покупатель:
          <div contentEditable
                           suppressContentEditableWarning
                           className={'ml-2'}
                           onBlur={e =>
                             editing(0, e.currentTarget.textContent, "counterparty")}>
          {editbleArray.header.counterparty}
        </div>
        </div>
        {editbleArray?.createTime && <div>Сформирован: {editbleArray?.createTime}</div>}
        <div className={'d-flex'}>
          Период
          <div contentEditable
                      suppressContentEditableWarning
                      className={'ml-2'}
                      onBlur={e =>
                        editing(0, e.currentTarget.textContent, "date")}>
          {editbleArray.header.date}
        </div>
        </div>
        {editbleArray.tableData.length === 0 ? <div onClick={() => addRow(0, 'down', 0)} style={{cursor: 'pointer'}}>
          Добавить строку <i className={"fa fa-plus ml-2 text-primary"}/>
        </div> : <br/>}
      </div>
      <Table striped bordered hover>
        <thead>
        <tr>
          <td>№</td>
          <td style={!showCol.name ? {} : style}>Документ/Операция</td>
          <td>Пассажир</td>
          <td>Назначение</td>
          <td>Описание</td>
          <td>Дата вылета</td>
          <td style={showCol.krs ? {} : style}>КРС покупателя</td>
          <td style={showCol.krs ? {} : style}>КРС Kayar</td>
          <td>Итого</td>
        </tr>
        </thead>
        <tbody>
        {editbleArray.tableData.map((inv, i) => {
          return <tr style={inv.transferred ? {backgroundColor: 'rgba(253, 57, 122,0.35)'} : {}}>
            <td>
              <div className={'d-flex gap-1'}>
                <div>{i + 1}.</div>
                <div className={'notVisible'} >{inv.from1C ? '1C' : 'Б'}</div>
              </div>
            </td>
            <td style={!showCol.name ? {} : style}
            >{inv.bookingId ?
              <Link to={inv.baseLink + inv.bookingId} target={'_blank'}>{inv.operation}</Link> : inv.operation}</td>
            <td contentEditable
                suppressContentEditableWarning
                onBlur={e => editing(i, e.currentTarget.textContent, "pax")}>{inv.pax}</td>
            <td contentEditable
                suppressContentEditableWarning style={{whiteSpace: "nowrap"}}
                onBlur={e => editing(i, e.currentTarget.textContent, "BSO")}>{inv.BSO}</td>
            <td contentEditable
                suppressContentEditableWarning
                onBlur={e => editing(i, e.currentTarget.textContent, "route")}>{inv.route}</td>
            <td contentEditable
                suppressContentEditableWarning
                onBlur={e => editing(i, e.currentTarget.textContent, "flightDate")}>{inv.flightDate}</td>
            <td contentEditable
                suppressContentEditableWarning className={'text-end'} style={showCol.krs ? {} : style}
                onBlur={e => editing(i, e.currentTarget.textContent, "krsBuyer")}>{inv.krsBuyer ? numberFormat(inv.krsBuyer) : ''}</td>
            <td contentEditable
                suppressContentEditableWarning className={'text-end'} style={showCol.krs ? {} : style}
                onBlur={e => editing(i, e.currentTarget.textContent, "krsKayar")}>{inv.krsKayar ? numberFormat(inv.krsKayar) : ''}</td>
            <td contentEditable
                suppressContentEditableWarning className={'text-end'}
                onBlur={e => editing(i, e.currentTarget.textContent, "total")}>{numberFormat(inv.total)}</td>
            <td style={{border: 'none'}} id={'notVisible'}>
              <MoreFunctionButton removeRow={() => removeRow(i)} addRow={(direction) => {
                addRow(i, direction)
              }}/>
            </td>
          </tr>
        })}

        <tr>
          <th colSpan={colSpan()}>Итого</th>
          <th style={showCol.krs ? {} : style}
              className={'text-end'}>{numberFormat(_.sumBy(editbleArray.tableData,'krsBuyer')) || 0}</th>
          <th style={showCol.krs ? {} : style}
              className={'text-end'}>{numberFormat(_.sumBy(editbleArray.tableData, 'krsKayar'))|| 0}</th>
          <th className={'text-end'}>{numberFormat(_.sumBy(editbleArray.tableData, 'total')) || 0}</th>
        </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default SaleTable;
