import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import ApiSelect from "../../base/components/forms/apiSelect";
import LoadingButton from "../../base/components/loadingButton";
import DownloadButton from "./downloadButton";
import {useLocation} from "react-router-dom";

const SearchForm = ({onSubmit, loading, info, edit}) => {
  const { search } = useLocation();
  const formController = useForm()
  const {handleSubmit,
    control,
    register,
  setValue} = formController

  useEffect(() => {
    if(search){
      const newInfo = {
        start: new URLSearchParams(search).get('start'),
        end: new URLSearchParams(search).get('end'),
        buyerId: new URLSearchParams(search).get('id'),
        withoutYana:true,
        fromBase:true
      }
      onSubmit(newInfo)
      setValue('start', new URLSearchParams(search).get('start'))
      setValue('end', new URLSearchParams(search).get('end'))
      setValue('buyerId', new URLSearchParams(search).get('id'))
    }
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-center gap-2 justify-content-between'}>
        {edit ?<div></div>:<div className={'d-flex align-items-center gap-2'}>
          <CustomDatePicker formController={formController}
                            label={"Дата c"}
                            value={moment().subtract(7, 'days').format('DD.MM.YYYY')}
                            name={`start`}
                            rules={{required: true}}/>
          <CustomDatePicker formController={formController}
                            label={"Дата по"}
                            value={moment().format('DD.MM.YYYY')}
                            name={`end`}
                            rules={{required: true}}/>
          <div style={{width: 200}}><Controller
            control={control}
            render={({field: {onChange, onBlur, value}}) => (
              <ApiSelect
                value={value}
                label={'Контрагент'}
                url={"/api/counterparty/searchInput"}
                cb={newVal => onChange(newVal)}
              />
            )}
            name={`buyerId`} rules={{required: true}}
          /></div>
          <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
            <input
              defaultChecked={true}
              {...register(`fromBase`)}
              type={"checkbox"}
            />{" "}
            Из базы
          </Form.Label>
          <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
            <input
              defaultChecked={true}
              {...register(`withoutYana`)}
              type={"checkbox"}
            />{" "}
            Без Яны
          </Form.Label>
        </div>}
        <div className={'d-flex align-items-center gap-2'}>
          {!edit && <LoadingButton loading={loading} title={'Получить'}/>}
          <DownloadButton info={info}
            // edit={edit}
          />
        </div>
      </form>

    </>
  );
};

export default SearchForm;
