import React, { useEffect, useState } from "react";
import TripForm from "../components/tripForm";
import { connect } from "react-redux";
import { CreateItem } from "../../../../redux/actions/base";
import { TripItemListRoute, TripListRoute } from "../../../routesConstants";
import axios from "../../../../helpers/axios";
import {useLocation, useNavigate} from "react-router";
import {Helmet} from "react-helmet";

const tripTempData = {
  items: [],
  descriptions: [],
  photos: [],
  documents: [],
  visibleToAccounts: [],
  countries: [],
  paxes: [],
  tickets: [],
  public: true
};

const TripCreatePage = ({ CreateItem }) => {
  const tickets = useState([]);
  const [ticketsData, setTicketsData] = tickets;
  const navigate = useNavigate();
  const callback = (values, cb) => {
    axios
      .put("/api/trips", values)
      .then(() => navigate(TripListRoute))
      .catch(() => cb());
  };

  return (
    <>
      <Helmet>
        <title>Поездка</title>
      </Helmet>
      <TripForm
        onSubmit={callback}
        data={tripTempData}
        tickets={tickets}
        title={"Создание поездки"}
      />
    </>
  );
};

export default connect(null, { CreateItem })(TripCreatePage);
