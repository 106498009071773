import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import AirportForm from "../components/airportForm";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { AirportListRoute } from "../../../routesConstants";
import { useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { Dropdown, Row, Col } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Process from "../../../../components/process";
import {Helmet} from "react-helmet";

const CityEditPage = props => {
  const [airport, setAirport] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    if (airport === null) {
      setLoader(true);
      props.GetItem("/api/locations/airports", id, res => {
        setAirport(res.data.data);
        setLoader(false);
      });
    }
  }, [airport, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem(
      "/api/locations/airports/" + id,
      values,
      cb,
      AirportListRoute
    );
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem(
      "/api/locations/airports/" + id,
      () => {},
      AirportListRoute
    );
  };
  const airportSyncCb = d => {
    setAirport(d.airport);
  };
  return (
    <>
      <Helmet>
        <title>{airport?.name || "Аэропорт"}</title>
      </Helmet>
      <Row>
        <Col lg={6}>
          <Portlet>
            <PortletHeader
              title={"Редактирование аэропорта"}
              toolbar={
                <PortletHeaderToolbar>
                  <BlockUi blocking={loader}>
                    <DropdownButton variant="outline-danger" title="Удалить">
                      <Dropdown.Item as="button" onClick={deleteAction}>
                        Да! Удалить
                      </Dropdown.Item>
                    </DropdownButton>
                  </BlockUi>
                </PortletHeaderToolbar>
              }
            />
            <BlockUi blocking={loader}>
              <AirportForm onSubmit={callback} data={airport} />
            </BlockUi>
          </Portlet>
        </Col>
        <Col lg={6}>
          <Process
            desc={"Достает координаты и русское название"}
            title={"Синхронизация c Places"}
            link={`/api/locations/airports/syncAirport/${id}`}
            cb={airportSyncCb}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(CityEditPage);
