import React from 'react';
import {Portlet, PortletHeader} from "../../../components/Portlet";
import CounterPartyForm from "../components/counterPartyForm";
import {connect} from "react-redux";
import {CreateItem} from "../../../redux/actions/base";
import { CounterpartyListRoute} from "../../routesConstants";
import {Helmet} from "react-helmet";


const CounterPartyCreatePage = ({CreateItem}) =>{

  const callback = (values, cb)=>{
    CreateItem('/api/counterparty', values, cb, CounterpartyListRoute )
  }
  return <>
    <Helmet>
      <title>Контрагент</title>
    </Helmet>
    <div className={"row"}>
      <div className="col-xl-6">
        <Portlet>
          <PortletHeader title={"Создание контрагента"}/>
          <CounterPartyForm onSubmit={callback}/>
        </Portlet>
      </div>
    </div>
  </>
}

export default connect(null, {CreateItem})(CounterPartyCreatePage)
