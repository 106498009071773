import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { useParams } from "react-router-dom";
import { TripListRoute } from "../../../routesConstants";
import TripForm from "../components/tripForm";
import { useNavigate } from "react-router";
import Loader from "../../../../components/loader";
import axios from "../../../../helpers/axios";
import {Helmet} from "react-helmet";

const TripEditPage = props => {
  const [trip, setTrip] = useState(null);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const tickets = useState([]);
  const [ticketsData, setTicketsData] = tickets;


  const { id } = useParams();
  const setNewTrip = tripModel => {
    tripModel.users = trip.users;
    tripModel.tickets = trip.tickets;
    console.log("superNEwModel ", tripModel);
    setTrip(tripModel);
  };
  useEffect(() => {
    if (trip === null) {
      setLoader(true);
      axios.get("/api/trips/"+id)
        .then(({data})=>{
          console.log('dataR',data)
          setTrip(data.data);
          setTicketsData(data.data.tickets);
          setLoader(false)
        })
        .catch(e=>{
          console.log('err',e)
          window.alert(e.response.data.message[0])
          navigate(TripListRoute)
      })
      // props.GetItem("/api/trips", id, res => {
      //   setTrip(res.data.data);
      //   setTicketsData(res.data.data.tickets);
      //   setLoader(false);
      // });
    }
  }, [trip, id, props]);
  const callback = (values, cb, type) => {
    const callBack = () => {
      cb();
      // window.location.reload()
    };

    props.UpdateItem("/api/trips/" + id, values, callBack);
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem("/api/trips/" + id, () => {}, TripListRoute);
  };
  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Поездка</title>
      </Helmet>
      {loader ? null : (
          <TripForm
            tripSetter={setNewTrip}
            tickets={tickets}
            onSubmit={callback}
            data={trip}
            title={"Редактирование поездки"}
          />
      )}
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(TripEditPage);
