import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../components/Portlet";
import {connect, useSelector} from "react-redux";
import { CreateItem, GetItem, UpdateItem } from "../../../redux/actions/base";
import {
  AirportListRoute,
  CityListRoute,
  CountryListRoute, UserEditRoute,
  UserListRoute
} from "../../routesConstants";
import * as yup from "yup";
import FormRow from "../../base/components/forms/formRow";
import { Col, Form, Row } from "react-bootstrap";
import CitySelect from "../../base/components/forms/citySelect";
import LoadingButton from "../../base/components/loadingButton";
import { Formik } from "formik";
import ChangePassword from "./components/ChangePassword";
import GenerateLoginToken from "./components/GenerateLoginToken";
import { useParams } from "react-router-dom";
import _, {values} from 'lodash'
import {Helmet} from "react-helmet";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .default(""),
  lastName: yup
    .string()
    .required()
    .default(""),
  phone: yup.string().default(""),
  operatorCode: yup
    .string().default(""),
  telegramUsername: yup.string().default(""),
  railwaysLogin: yup.string().default(""),
  ratehawkLogin: yup.string().default(""),
  active: yup.bool().default(false),
  role: yup
    .string()
    .required()
    .default("employee"),
  sendTgNotifications: yup
    .array()
    .of(
      yup.lazy(value =>
        typeof value === "number" ? yup.number() : yup.string()
      )
    ).default([])
});
const UserEditPage = props => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const { roles } = useSelector(state => state.auth.user);


  const { id } = useParams();
  useEffect(() => {
    if (user === null) {
      setLoader(true);
      props.GetItem("/api/users", id, res => {
        setUser(res.data.user);
        console.log(res);
        setLoader(false);
      });
    }
  }, [user, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem("/api/users/" + id, values, cb, roles.indexOf("admin") !== -1 ? UserListRoute : UserEditRoute+id);
  };
  return (
    <>
      <Helmet>
        <title>{user ? `${user.firstName} ${user.lastName}` : 'Пользователь'}</title>
      </Helmet>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Редактирование пользователя"} />
            <Formik
              initialValues={
                user ? validationSchema.cast(user) : validationSchema.cast()
              }
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                console.log("form sent");
                setSubmitting(true);
                callback(values, () => {
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue
              }) => {
                console.log("values1",values);
                console.log("1c",values.sendTgNotifications.includes("tech_1c"));
                return (
                  <form noValidate={true} onSubmit={handleSubmit}>
                    <PortletBody>
                      <FormRow
                        value={values.firstName}
                        name={"firstName"}
                        title={"Имя"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.lastName}
                        name={"lastName"}
                        title={"Фамилия"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />

                      <FormRow
                        value={values.phone}
                        name={"phone"}
                        title={"Телефон"}
                        placeholder={"+1 111 1111111"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />

                      <FormRow
                        value={values.telegramUsername}
                        name={"telegramUsername"}
                        title={"Имя в телеграмме"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.railwaysLogin}
                        name={"railwaysLogin"}
                        title={"Имя ЖД"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.ratehawkLogin}
                        name={"ratehawkLogin"}
                        title={"Имя Rate Hawk"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.operatorCode}
                        name={"operatorCode"}
                        title={"Код операт. 1С"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow value={values.email} title={"Email"} disabled={true}/>
                      {roles.indexOf("admin") === -1 ? <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            Роль
                          </Form.Label>
                          <Col sm={10} className={'mt-2'}>
                            <Form.Check
                              value={values.role}
                              name={"role"}
                              type={"radio"}
                              label={rolesTranslater[values.role]}
                              id={values.role}
                              checked={true}
                              onChange={handleChange}
                              touched={touched}
                            />
                          </Col>
                        </Form.Group> :
                        <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            Роли
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Check
                              value={"account"}
                              name={"role"}
                              type={"radio"}
                              label={`Пользователь`}
                              id={`User`}
                              checked={values.role === "account"}
                              onChange={handleChange}
                              touched={touched}
                            />
                            <Form.Check
                              value={"accountant"}
                              name={"role"}
                              type={"radio"}
                              label={`Бухгалтер`}
                              id={`User`}
                              checked={values.role === "accountant"}
                              onChange={handleChange}
                              touched={touched}
                            />
                            <Form.Check
                              value={`employee`}
                              name={"role"}
                              type={"radio"}
                              label={`Сотрудник`}
                              id={`Employee`}
                              checked={values.role === "employee"}
                              onChange={handleChange}
                              touched={touched}
                            />
                            <Form.Check
                              value={`admin`}
                              name={"role"}
                              type={"radio"}
                              label={`Администратор`}
                              id={`Admin`}
                              checked={values.role === "admin"}
                              onChange={handleChange}
                              touched={touched}
                            />
                            <Form.Check
                              value={`tours_user`}
                              name={"role"}
                              type={"radio"}
                              label={`Создание туров`}
                              id={`tours_user`}
                              checked={values.role === "tours_user"}
                              onChange={handleChange}
                              touched={touched}
                            />
                          </Col>
                        </Form.Group>}
                      <hr/>
                      {!values.telegramChatId ? <>
                        Чтобы подписаться на уведомления вам нужно написать нашему боту в Телеграмме. Обратитесь к
                        администратору
                      </> : <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Получать уведомления
                        </Form.Label>
                        <Col sm={10} className={'mt-2'}>

                          <Form.Check
                            value={"parse_check_errors"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Ошибки парса"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("parse_check_errors")}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"trips_and_offers"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Предложения/Поездки"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("trips_and_offers")}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"unpaid_ratehawk"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Неоплаченное RateHawk"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("unpaid_ratehawk")}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"canceled_ratehawk"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Отмененные RateHawk"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("canceled_ratehawk")}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"tech_1c"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Тех. 1С"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("tech_1c")}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"tech_google_sheets"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Тех. Гугл Таблицы"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("tech_google_sheets")}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"new_services"}
                            name={"sendTgNotifications"}
                            type={"checkbox"}
                            label={"Тур-услуги"}
                            id={values.sendTgNotifications}
                            checked={values.sendTgNotifications.includes("new_services")}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>}
                      <hr/>
                      <Form.Group as={Row}>
                        <Col sm={{span: 10, offset: 2}}>
                          <Form.Check
                            name={"active"}
                            type={"checkbox"}
                            id={`active`}
                            label={"Активирован"}
                            checked={values.active}
                            touched={touched}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>

                    </PortletBody>
                    <PortletFooter>
                      <Row>
                        <Col md={{ span: 10, offset: 2 }}>
                          <LoadingButton
                            title={"Сохранить"}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </PortletFooter>
                  </form>
                );
              }}
            </Formik>
          </Portlet>
        </div>
        <div className="col-xl-6">
          <ChangePassword userId={id} url={"/api/users/changePassword"} />
          {/*<GenerateLoginToken userId={id} url={"/api/users/generateToursLoginToken"}/>*/}
        </div>
      </div>
    </>
  );
};

export default connect(null, { GetItem, UpdateItem })(UserEditPage);

const rolesTranslater = {
  "account":"Пользователь",
  "accountant":"Бухгалтер",
  "employee":"Сотрудник",
  "admin":"Администратор",
  "tours_user":"Создание туров",
}
