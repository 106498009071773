import React, {useState} from "react";
import {connect} from "react-redux";
import {AirportCreateRoute, AirportEditRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import {Link} from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import {Tab, Tabs} from "react-bootstrap";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import {Helmet} from "react-helmet";

const CityList = (props)=>{
  const columns = [
    {
      Header: 'Название',
      accessor: 'name',
      Cell: ({value, row}) => <Link to={AirportEditRoute + row.original._id } >{value}</Link>
    },
    {
      Header: 'IATA код',
      accessor: 'iataCode',
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (<Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={AirportEditRoute + row.original._id}><i className={"la la-edit"}/></Link>)
    }
  ]

  const [query, setQuery] = useState("");
  const [tabIndex, setTabIndex] = useState("home");
  return <>
    <Helmet>
      <title>Аэропорты</title>
    </Helmet>
    <Tabs
      variant="pills"
      id="controlled-tab-example"
      activeKey={tabIndex}
      onSelect={key => setTabIndex(key)}
    >
      <Tab eventKey="home" title="Список">
        <div className={"kt-grid__item"}>
          <Portlet>
            <PortletHeader
              title={"Список стран"}
              toolbar={
                <PortletHeaderToolbar>
                  <PortletHeaderSearch value={query} cb={(v)=>{setQuery(v)}}/>
                  <Link to={AirportCreateRoute} className="btn btn-sm btn-brand btn-elevate btn-icon-sm">
                    <i className="la la-plus"/>
                    Добавить
                  </Link>
                </PortletHeaderToolbar>}
            />
            <PortletBody>
              <DataTable columns={columns} search={query} url={`/api/locations/airports`}/>
            </PortletBody>
          </Portlet>
        </div>
      </Tab>

      {/*<Tab eventKey="profile" title="Действия"  className="tab-no-background">*/}
      {/*</Tab>*/}
    </Tabs>
  </>
}


export default connect(null, {})(CityList)
