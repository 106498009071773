import React, {useEffect} from 'react';
import {Controller, useController, useFieldArray, useForm} from "react-hook-form";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import {Button, Form} from "react-bootstrap";
import LoadingButton from "../../base/components/loadingButton";
import DownloadButton from "./downloadButton";
import {useLocation} from "react-router-dom";
import moment from "moment/moment";

const optionsTempData = {
  start:moment().subtract(7, 'days').format('DD.MM.YYYY'),
  end:moment().format('DD.MM.YYYY'),
  buyerId:'',
  fromBase:true,
  withoutYana: false
}
const OptionsForm = ({cb, loading, info, edit}) => {
  const { search } = useLocation();
  const formController = useForm()
  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState:{errors}
  } = formController
  const optionsControl = useFieldArray({
    control,
    name: `options`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
  });
  useEffect(() => {
    optionsControl.append(optionsTempData)
  }, []);
  const onSubmit = (data) =>{
    cb(data)
  }
  useEffect(() => {
    if(search){
      const newInfo = {
        start: new URLSearchParams(search).get('start'),
        end: new URLSearchParams(search).get('end'),
        buyerId: new URLSearchParams(search).get('id'),
        withoutYana:true,
        fromBase:true
      }
      cb({options:[
          newInfo
        ]})
      optionsControl.update(0,newInfo)
    }
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-end justify-content-between'}>
      <div>
        {!edit && optionsControl.fields.map((f,i)=> {
          const prefix = `options[${i}].`
          return <div className={'d-flex align-items-center justify-content-between mt-2'} key={i}>
            <div className={'d-flex align-items-center gap-2'}>
              <CustomDatePicker formController={formController}
                                label={"Дата начала"}
                                name={`${prefix}start`}
                                value={getValues().options[i].start}
                                rules={{required: true}}/>
              <CustomDatePicker formController={formController}
                                label={"Дата конца"}
                                name={`${prefix}end`}
                                value={getValues().options[i].end}
                                rules={{required: true}}/>
              <div style={{minWidth: '250px'}}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({field: {onChange, onBlur, value}, fieldState:{error}}) => (
                    <ApiSelect
                      value={value}
                      label={'Покупатель'}
                      hasError={error}
                      url={"/api/counterparty/searchInput"}
                      cb={newVal => onChange(newVal)}
                    />
                  )}
                  name={`${prefix}buyerId`}
                />
              </div>
              <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
                <input
                  defaultChecked={true}
                  {...register(`${prefix}fromBase`)}
                  type={"checkbox"}
                />{" "}
                Из базы
              </Form.Label>
              <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
                <input
                  defaultChecked={true}
                  {...register(`${prefix}withoutYana`)}
                  type={"checkbox"}
                />{" "}
                Без Яны
              </Form.Label>
              {/*{i+1===optionsControl.fields.length &&*/}
              {/*  <Button size={"sm"}*/}
              {/*          variant={'outline-primary'}*/}
              {/*          onClick={() => optionsControl.append(optionsTempData)}>*/}
              {/*    <i className={"fa fa-plus"}/> Ещё*/}
              {/*  </Button>}*/}
            </div>

            {/*<Button size={"sm"}*/}
            {/*        className={'ml-2'}*/}
            {/*        variant={'outline-secondary'}*/}
            {/*        disabled={optionsControl.fields.length ===1}*/}
            {/*        onClick={() => optionsControl.remove(i)}>*/}
            {/*  <i className={"fa fa-trash"}/>*/}
            {/*</Button>*/}
          </div>
        })}

      </div>

      <div className={'d-flex align-items-center gap-2'}>
        {!edit && <LoadingButton
          title={"Сгенерировать таблицу"}
          disabled={loading}
          loading={loading}
        />}
        <DownloadButton info={info} edit={edit}/>
      </div>

    </form>
  );
};

export default OptionsForm;

// <Controller
//   control={control}
//   render={({ field: { onChange, onBlur, value } }) => (
//     <ApiSelect
//       value={value}
//       label={'Перевозчик(Контрагент)'}
//       url={"/api/counterparty/searchInput"}
//       queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}
//       cb={newVal => onChange(newVal)}
//     />
//   )}
//   name={`${prefix}.optionalFields.carrier`}
// />
