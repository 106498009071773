import React, {useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Link} from "react-router-dom";
import {DocumentsCreateRoute, DocumentsEditRoute, TouristServicesEditRoute} from "../../routesConstants";
import numberFormat from "../../../helpers/numberFormat";
import DataTable from "../../../components/dataTable";
import PortletHeaderSearch from "../../base/components/forms/portlerHeaderSearch";
import {Form} from "react-bootstrap";
import EditModal from "../components/editModal";
import {Helmet} from "react-helmet";

const documentType = {
  'passport' :'Паспорт',
  'identification':'Удостоверение',
  'visa':'Виза'
}
const DocumentsListPage = () => {
  const [query, setQuery] = useState({searchString:'', expired:true})

  const columns = [
    {
      Header: 'Фамилия/Имя',
      Cell: ({value,row})=>(
        <EditModal id={row.original.id}>{row.original.lastName.toUpperCase()} {row.original.firstName.toUpperCase()}</EditModal>
      )
    },
    {
      Header: '№ Документа',
      accessor: 'documentNumber',
    },
    {
      Header: 'Тип',
      accessor: 'documentType',
      Cell: ({value,row})=>(<>{documentType[value]}</>)
    },
    {
      Header: 'Истекает',
      accessor: 'expirationDate',
    },
    {
      Header: "Годен",
      accessor: "expired",
      Cell: ({ value, row }) => (
        <div>
          {value ? <i className={"fa fa-times-circle text-danger"}/>: <i className="fa fa-check-circle text-success" />}
        </div>
      ),
    },
  ]
  return (
    <>
      <Helmet>
        <title>Документы</title>
      </Helmet>
      <Portlet>
        <PortletHeader title={'Документы пассажиров'} toolbar={<PortletHeaderToolbar>
          <PortletHeaderSearch value={query.searchString}
                               cb={(v)=>{setQuery(old=>({...old, searchString:v}))}}/>
          <Form.Label className={'d-flex align-items-center gap-2 m-2'}>
            <input
              defaultChecked={query.expired}
              onChange={(e) => {
                setQuery(q => ({...q, expired: e.target.checked}))
              }}
              type={"checkbox"}
            />{" "}
            Просроченные
          </Form.Label>
          <Link to={DocumentsCreateRoute} className="btn btn-sm btn-primary">
            <i className="la la-plus"/>
            Добавить
          </Link>
        </PortletHeaderToolbar>}/>
        <PortletBody>
          <DataTable columns={columns} search={query} url={`/api/documents/`}/>
        </PortletBody>
      </Portlet>
    </>

  );
};

export default DocumentsListPage;
