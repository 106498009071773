import React from 'react';
import numberFormat from "../../../helpers/numberFormat";
import PaidButton from "./PaidButton";
import {Link} from "react-router-dom";
import {TouristServicesEditRoute} from "../../routesConstants";

const OsvItem = ({order, cb}) => {
  console.log('order',order.baseUrl+order.id)
  return (
    <React.Fragment>
      <td>
        <Link to={order.baseURL+order.id} target={'_blank'}>
          {order.types.map((t,i)=>{
            return <React.Fragment key={i}>
              {t}{i!==order.types.length && <br/>}
            </React.Fragment>
          })}
        </Link>
      </td>
      <td align={'center'}>{order.issueDate.slice(0,10)}</td>
      <td align={'center'}>{numberFormat(order.total)} KZT</td>
      <td align={'center'}>
        <PaidButton paid={false}
                    url={`${order.type === "SRV" ? '/api/touristServices/accountant/markPaid/' : '/api/air/airBooking/markPaid/'}${order.id}`}
                    cb={cb}/></td>
    </React.Fragment>
  );
};

export default OsvItem;
