import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Portlet, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import SearchForm from "../components/searchForm";
import axios from "../../../helpers/axios";
import numberFormat from "../../../helpers/numberFormat";
import DataTable from "react-data-table-component";
import _ from "lodash";
import moment from "moment";
import {getSearchParam} from "../components/getSearchParam";

const OneCounterparty = () => {
  const { search } = useLocation();
  const [data, setData] = useState()
  useEffect(() => {
    if(search){
      axios.post('/api/racoon/pdfParse/incomeReport/one',{
        ...getSearchParam(search),
        groupBySupplier: false
      }).then(({data})=>{
        data.table.forEach(i=>{
          i.total = i.krs1C+i.krsKayar1C+i.krsSite
          i.issueDate = moment(i.issueDate, 'DD.MM.YY').unix()
        })
        setData(data.table)
      })
    }
  }, []);
  console.log('data', data)
  const columns = [
    {
      name: '',
      selector: row => row.counterparty,
      sortable: true,
    },
    {
      name: 'Крс 1с',
      selector: row => row.krs1C,
      cell: row => `${numberFormat(row.krs1C)}`,
      sortable: true,
      width:'120px'
    },
    {
      name: 'КрсКаяр 1с',
      selector: row => row.krsKayar1C,
      cell: row => `${numberFormat(row.krsKayar1C)}`,
      sortable: true,
      width:'120px'
    },
    {
      name: 'Крс Сайт',
      selector: row => row.krsSite,
      cell: row => `${numberFormat(row.krsSite)}`,
      sortable: true,
      width:'120px'
    },
    {
      name: 'Итого',
      selector: row => row.total,
      cell: row => `${numberFormat(row.total)}`,
      sortable: true,
      width:'120px'
    },
  ];
  const detailColums = [
    {
      id:'issueDate',
      name: 'Выписан',
      selector: row => row.issueDate,
      cell:row => moment.unix(row.issueDate).format('DD.MM.YY'),
      sortable: true,
      width:'100px'
    },
    {
      name: 'Билет',
      selector: row => row.ticket,
      width:'150px',
    },
    {
      name: 'Пассажир',
      selector: row => row.pax,
      sortable: true,
      width:'200px',
      wrap:true
    },
    {
      name: 'Маршрут',
      selector: row => row.route,
      wrap:true
    },
    ...columns.slice(1)
  ]

  return (
    <Portlet>
      <PortletHeader title={'Доход'} toolbar={<PortletHeaderToolbar>
        <SearchForm one
                    cb={(data, search)=>{
          data.table.forEach((i,index)=>{
            i.total = i.krs1C+i.krsKayar1C+i.krsSite
            i.issueDate = moment(i.issueDate, 'DD.MM.YY').unix()
          })
          setData(data.table)
          var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname +
            `?start=${search.start}&end=${search.end}&id=${search.counterparty}`;
          window.history.pushState({path:newurl},'',newurl)
        }}
                    url={'/api/racoon/pdfParse/incomeReport/one'}/>
      </PortletHeaderToolbar>}/>
      {data && <><DataTable
        columns={columns}
        data={[{
          counterparty: 'Итого',
          krs1C: _.sumBy(data, 'krs1C') || 0,
          krsKayar1C: _.sumBy(data, 'krsKayar1C') || 0,
          krsSite: _.sumBy(data, 'krsSite') || 0,
          total: _.sumBy(data, 'total') || 0,
        }]}
        conditionalRowStyles={[{
          when: row => typeof row.counterparty === 'string',
          style: {backgroundColor: '#00800059'}
        }]}
      />
        <DataTable
          columns={detailColums}
          fixedHeader={true}
          defaultSortFieldId="issueDate"
          fixedHeaderScrollHeight={'73vh'}
          defaultPageSize={3}
          data={data}
        />
      </>}
    </Portlet>
  );
};

export default OneCounterparty;
