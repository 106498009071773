import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { AirplaneListRoute } from "../../../routesConstants";
import { useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { Dropdown, Row, Col, Form } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import AirplaneForm from "../components/airplaneForm";
import {Helmet} from "react-helmet";

const AirplaneEditPage = props => {
  const [airplane, setAirplanes] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    if (airplane === null) {
      setLoader(true);
      props.GetItem("/api/air/airplanes", id, res => {
        setAirplanes(res.data.data);
        setLoader(false);
      });
    }
  }, [airplane, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem("/api/air/airplanes/" + id, values, cb, AirplaneListRoute);
  };
  return (
    <>
      <Helmet>
        <title>{airplane?.originalName || airplane?.name || 'Самолет'}</title>
      </Helmet>
      <Row>
        <Col lg={6}>
          <Portlet>
            <PortletHeader title={"Редактирование самолета"} />
            <BlockUi blocking={loader}>
              <AirplaneForm onSubmit={callback} data={airplane} />
            </BlockUi>
          </Portlet>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  AirplaneEditPage
);
