import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import {Portlet, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import SearchForm from "../components/searchForm";
import numberFormat from "../../../helpers/numberFormat";
import _ from 'lodash'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const DetailIncomePage = () => {

  const [data,setData] = useState()
  const borderS = '1px solid rgba(100, 108, 154, 0.3)'
  const [sortBy, setSortBy] = useState('')
  const [period, setPeriod] = useState()
  const [direction, setDirection] = useState(-1)

  const generateTable = (row,data) =>{
    let allSuppliers = data.uniqSuppliers.avia.concat(data.uniqSuppliers.services)
    let arr = Array.apply(null, Array(allSuppliers.length))
    row.avia.forEach((sup)=>{
      arr[data.uniqSuppliers.avia.indexOf(sup.supplier)] = sup
    })
    row.services.forEach((sup)=>{
      arr[data.uniqSuppliers.services.indexOf(sup.supplier)+data.uniqSuppliers.avia.length] = sup
    })
    arr.forEach((obj, i)=>{
      if(!!!obj){
        arr[i]= {supplier:allSuppliers[i], krs:0,netto:0,summ:0}
      }
    })
    arr.push(row.totals)
    return {counterparty:row.counterparty, columns:arr}
  }
  // asc desc



  const generStyle = (i,arr) =>{
    return i===data.uniqSuppliers.avia.length -1  || i===arr.length-1 || i===arr.length-2?
      {borderRight:'2px solid rgba(100, 108, 154, 0.3)', fontWeight:'bold'} :
      {borderRight:borderS}
  }



  const sorting = (colum, i) => {
    console.log('sort', colum, sortBy)
    let allSuppliers = data.uniqSuppliers.avia.concat(data.uniqSuppliers.services)
    allSuppliers.push('Итого')
    console.log('index', i,allSuppliers[i], allSuppliers.indexOf(colum))

    const dir = sortBy !== colum  ? -1 : direction ===-1 ? 1 : -1
    setDirection(dir)
    const newArray = _.sortBy(data.table, [r => {
      return r.columns[i].summ * dir
    }])
    setData(old => ({...old, table: newArray}))

    setSortBy(colum)
  }
  const icon = (name) => {
    if (sortBy === name) {
      return <i className={`fa fa-chevron-${direction === 1 ? 'up' : 'down'}`}/>
    }
    return ""
  }

  return (
    <>
      <Helmet>
        <title>Доход по поставщикам</title>
      </Helmet>
      <Portlet>
        <PortletHeader title={'Подробная таблица'} toolbar={<PortletHeaderToolbar>
          <SearchForm url={"/api/racoon/pdfParse/detailedReport"} cb={(data, period)=>{
            const all = data.table.map((row, i) => (generateTable(row,data)))
            setPeriod(period)
            const finalData = {
              ...data,
              table: all}
            setData(finalData)
          }}/>
        </PortletHeaderToolbar>}/>
        {data && <div className="tableFixHead">
          <table>
            <thead >
            <tr>
              <th></th>
              <th colSpan={data.uniqSuppliers.avia.length}>Билеты</th>
              <th colSpan={data.uniqSuppliers.services.length} style={{borderLeft:'2px solid rgba(100, 108, 154, 0.3)'}}>Тур/услуги</th>
              <th></th>
            </tr>
            <tr >
              <th>Контрагент</th>
              {data.uniqSuppliers.avia.map((n, i) =>
                (<th key={i} className={'pointer'}
                     onClick={()=>sorting(n, i)}>{n} {icon(n)}</th>))}
              {data.uniqSuppliers.services.map((n, i) =>
                (<th key={i} className={'pointer'}
                     onClick={()=>sorting(n, data.uniqSuppliers.avia.length+i)}
                     style={{borderLeft: i===0 ?'2px solid rgba(100, 108, 154, 0.3)':''}}>{n}{icon(n)}</th>))}
              <th>Итого</th>
            </tr>
            </thead>
            <tbody>
            <tr style={{backgroundColor:"#d9ead3"}}>
              <th rowSpan={3} style={{backgroundColor:"#d9ead3"}}>Гранд итог</th>
              {data.totals.map((c, i) => (<td key={i} style={generStyle(i, data.totals)}>{numberFormat(c.netto)}</td>))}
            </tr>
            <tr style={{backgroundColor:"#d9ead3"}}>
              {data.totals.map((c, i) => (<td key={i} style={generStyle(i, data.totals)}>{numberFormat(c.krs)}</td>))}
            </tr>
            <tr style={{borderBottom: borderS,backgroundColor:"#d9ead3"}}>
              {data.totals.map((c, i) => (<td key={i} style={generStyle(i,data.totals)}>{numberFormat(c.summ)}</td>))}
            </tr>
            {data.table.map((row, index) => (<React.Fragment key={index}>
              <tr>
                <th rowSpan={3}><Link to={{
                  pathname:"/income/oneGrouped",
                  search: `?start=${period.start}&end=${period.end}&id=${row.counterparty.id}&unComp=${period.uncompleted}`
                }}
                                      target={'_blank'} >
                  {row.counterparty.name}
                </Link></th>
                {row.columns.map((c, i) => (<td key={i} style={generStyle(i, row.columns)}>{numberFormat(c.netto)}</td>))}
              </tr>
              <tr>
                {row.columns.map((c, i) => (<td key={i} style={generStyle(i, row.columns)}>{numberFormat(c.krs)}</td>))}
              </tr>
              <tr style={{borderBottom: borderS}}>
                {row.columns.map((c, i) => (<td key={i} style={generStyle(i, row.columns)}>{numberFormat(c.summ)}</td>))}
              </tr>
            </React.Fragment>))}
            </tbody>
          </table>
        </div>}
      </Portlet>
    </>

  );
};

export default DetailIncomePage;
