import React, {useEffect, useState} from 'react';
import axios from "../../../helpers/axios";
import reviseItemTypes from "../helpers/reviseItemTypes";
import sayError from "../../../helpers/sayError";
import {noDispHeader} from "../../../helpers/errorTranslate";

const SaveReviseItemsBut = ({data, files, reviseType, startDate, endDate, cb}) => {
  const [uploadStatus, setUploadStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setUploadStatus([...files]);
  }, [files]);

  function uploadData() {
    const req = {
      name: reviseItemTypes[reviseType],
      dataJson: JSON.stringify(data),
      startDate,
      endDate,
      reviseType,
    };
    if(reviseType !== "aruana-kayar" && reviseType !== "kayar-site" && reviseType !== "yana-kayar"){
      req.documents = uploadStatus.map(u => {
        return {
          name: u.name,
          url: u.fileUrl,
          originalName: u.file.name
        };
      })
    }else{
      req.documents = []
    }
    console.log("req", req);
    axios.put(`/api/racoon/revise/item`, req, {headers:noDispHeader})
      .then(()=>{
        console.log('reload')
        cb()
      })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }
  const upload = async () => {
    setLoading(true);
    if(reviseType === "aruana-kayar" || reviseType === "kayar-site" || reviseType === "yana-kayar"){
      uploadData();
    }else{
      for (var file of uploadStatus) {
        const data = new FormData();
        data.append("file", file.file);
        data.append("folder", 'revises');
        const resp = await axios.post("/api/misc/cloudStorageUpload", data, {headers: {...noDispHeader,
            'Content-Type':"application/x-www-form-urlencoded"}});
        console.log("resp", resp);
        file.uploaded = true;
        file.fileUrl = resp.data.fileUrl;
        setUploadStatus([...uploadStatus]);
      }
    }

  };
  useEffect(() => {
    if (
      uploadStatus &&
      uploadStatus.length &&
      uploadStatus.every(u => u?.uploaded)
    ) {
      uploadData();
    }
  }, [uploadStatus]);
  return (
    <button className={"btn btn-outline-success btn-sm ml-5"}
            onClick={()=>upload()}
    >
      {loading ? 'Загрузка...' :'Сохранить изменения'}
    </button>
  );
};

export default SaveReviseItemsBut;
