import React, { useState, useEffect, Fragment } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import TemplateForm from "../components/templateForm";
import { connect } from "react-redux";
import { CreateItem, StartProcess } from "../../../../redux/actions/base";
import { AirBookingTemplateListRoute } from "../../../routesConstants";
import VarInfoTable from "../components/varInfoTable";
import {
  Row,
  Col,
  Table,
  FormControl,
  Form,
  Button,
  Alert
} from "react-bootstrap";
import _ from "lodash";
import parser from "../helpers/parser";
import testBooking from "../data/testBooking";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ParserSendMess from "../../components/parserSendMess";
import {Helmet} from "react-helmet";

const AirBookingParsePage = ({ StartProcess }) => {
  const [language, setLanguage] = useState("ru");
  const [parseResult, setParseResult] = useState();
  const [showResult, setShowResult] = useState(false);
  const [text, setText] = useState("");
  const [templates, setTemplates] = useState();
  const [transformedBooking, setTransformedBooking] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();

  useEffect(() => {
    if (!templates) {
      setLoading(true);
      StartProcess("/api/parsers/airBooking/getTemplates", async ({ data }) => {
        setTemplates(data.items);
        selectTemplate(data.items[0]._id, data.items);
        setLoading(false);
      });
    }
  }, []);
  const selectTemplate = (id, templates) => {
    const selectedTemplate = _.find(templates, { _id: id });
    setSelectedTemplate(selectedTemplate);
    if (parseResult) {
      transformBooking(parseResult, selectedTemplate);
    }
  };
  const transformBooking = () => {
    setTransformedBooking(
      parser(selectedTemplate, language, parseResult, params)
    );
  };
  const [priceForm, setPriceForm] = useState({
    adultEcomon: 0,
    adultPremium: 0,
    adultBusiness: 0,
    adultFirst: 0,
    youthEcomon: 0,
    youthPremium: 0,
    youthBusiness: 0,
    youthFirst: 0,
    infantEcomon: 0,
    infantPremium: 0,
    infantBusiness: 0,
    infantFirst: 0
  });
  const [params, setParams] = useState({
    flown: true,
    comment: ""
  });

  const [loading, setLoading] = useState(false);
  const uploadText = () => {
    setLoading(true);
    StartProcess(
      "/api/parsers/airBooking/parse",
      ({ data }) => {
        if (data.success) {
          setParseResult(data.result);
          setShowResult(true);
        }
        setLoading(false);
      },
      { text: text }
    );
  };
  useEffect(() => {
    if (parseResult) {
      transformBooking();
    }
  }, [selectedTemplate, language, parseResult]);
  return (
    <>
      <Helmet>
        <title>Парс авиа</title>
      </Helmet>
      <Row>
        <Col md={6}>
          <Portlet>
            <PortletHeader title={"Цена"} />
            <PortletBody>
              <Table>
                <tbody>
                  <tr>
                    <td></td>
                    <td>Взрослый</td>
                    <td>Подросток</td>
                    <td>Младенец</td>
                  </tr>
                  <tr>
                    <td className="align-middle">Эконом</td>
                    <td>
                      <FormControl
                        name={"adultEcomon"}
                        value={priceForm.adultEcomon}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            adultEcomon: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"youthEcomon"}
                        value={priceForm.youthEcomon}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            youthEcomon: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"infantEcomon"}
                        value={priceForm.infantEcomon}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            infantEcomon: val.target.value
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Премиум</td>
                    <td>
                      <FormControl
                        name={"adultPremium"}
                        value={priceForm.adultPremium}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            adultPremium: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"youthPremium"}
                        value={priceForm.youthPremium}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            youthPremium: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"infantPremium"}
                        value={priceForm.infantPremium}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            infantPremium: val.target.value
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Бизнес</td>
                    <td>
                      <FormControl
                        name={"adultBusiness"}
                        value={priceForm.adultBusiness}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            adultBusiness: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"youthBusiness"}
                        value={priceForm.youthBusiness}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            youthBusiness: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"infantBusiness"}
                        value={priceForm.infantBusiness}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            infantBusiness: val.target.value
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Первый</td>
                    <td>
                      <FormControl
                        name={"adultFirst"}
                        value={priceForm.adultFirst}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            adultFirst: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"youthFirst"}
                        value={priceForm.youthFirst}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            youthFirst: val.target.value
                          })
                        }
                      />
                    </td>
                    <td>
                      <FormControl
                        name={"infantFirst"}
                        value={priceForm.infantFirst}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            infantFirst: val.target.value
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </PortletBody>
          </Portlet>
          <Col xs={12}>
            {parseResult?.convertedBooking?.info &&
            parseResult?.convertedBooking?.info.length ? (
              <Alert variant="info">
                <div className={"alert-text"}>
                  {parseResult?.convertedBooking?.info.map((text, i) => (
                    <p key={i}>{text}</p>
                  ))}
                </div>
              </Alert>
            ) : null}
          </Col>
        </Col>
        <Col md={6}>
          <Portlet>
            <PortletHeader
              title={"Бронирование"}
              toolbar={
                <PortletHeaderToolbar>
                  {showResult ? (
                    <>
                      <ParserSendMess parseText={text} type={'airBooking'}/>
                      <Button onClick={() => setShowResult(false)}>Форма</Button>
                    </>
                  ) : (
                    <Button
                      disabled={text.length === 0}
                      className={
                        loading
                          ? "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light"
                          : null
                      }
                      onClick={() => uploadText(text)}
                    >
                      Загрузить
                    </Button>
                  )}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              {showResult ? (
                <div>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Язык</Form.Label>
                    <Form.Control
                      value={language}
                      as="select"
                      onChange={item => {
                        setLanguage(item.target.value);
                      }}
                    >
                      <option value={"ru"}>Русский</option>
                      <option value={"en"}>Английский</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Шаблон</Form.Label>
                    <Form.Control
                      value={selectedTemplate._id}
                      as="select"
                      onChange={item => {
                        selectTemplate(item.target.value, templates);
                      }}
                    >
                      {templates &&
                        templates.map((template, i) => {
                          return (
                            <option key={i} value={template._id}>
                              {template.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                  {transformedBooking &&
                    transformedBooking.split("\n").map((item, key) => {
                      return (
                        <Fragment key={key}>
                          {item}
                          <br />
                        </Fragment>
                      );
                    })}
                  <CopyToClipboard text={transformedBooking}>
                    <Button>Копировать</Button>
                  </CopyToClipboard>
                </div>
              ) : (
                <div>
                  <Row>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Не отображать сегменты по которым уже отлетали"
                        checked={params.flown}
                        onChange={() =>
                          setParams({ ...params, flown: !params.flown })
                        }
                      />
                      <br />
                      <FormControl
                        name={"comment"}
                        value={params.comment}
                        placeholder={"Комментарий"}
                        onChange={val =>
                          setPriceForm({
                            ...priceForm,
                            comment: val.target.value
                          })
                        }
                      />
                      <br />
                      <Form.Control
                        name={"text"}
                        value={text}
                        onChange={val => setText(val.target.value)}
                        as={"textarea"}
                        placeholder={"Бронь"}
                        rows={20}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </PortletBody>
          </Portlet>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { StartProcess })(AirBookingParsePage);
