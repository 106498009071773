import React, {useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import PortletHeaderSearch from "../../base/components/forms/portlerHeaderSearch";
import {Link} from "react-router-dom";
import {AirBookingCreateRoute, AirBookingEditRoute, RailCreateRoute, RailEditRoute} from "../../routesConstants";
import DataTable from "../../../components/dataTable";
import moment from "moment/moment";
import {Helmet} from "react-helmet";

const RailwaysList = () => {
  const [query, setQuery] = useState({});
  const columns = [
    {
      Header: 'Код бронирования',
      accessor: 'orderNumber',
      Cell: ({value, row}) => <Link to={RailEditRoute + row.original.id } >{value}</Link>
    },
    {
      Header: 'Дата выписки',
      accessor: 'issueDate',
    },
    {
      Header: 'Cоздал',
      accessor: 'issuedBy',
    },
    {
      Header: 'Покупатель',
      accessor: 'buyer',
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (<Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={RailEditRoute + row.original.id}><i className={"la la-edit"}/></Link>)
    }
  ]
  return (
    <>
      <Helmet>
        <title>ЖД</title>
      </Helmet>
      <Portlet>
        <PortletHeader
          title={"Список ЖД бронирований"}
          toolbar={
            <PortletHeaderToolbar>
              <PortletHeaderSearch value={query} cb={(v)=>{setQuery({ticketNumber:v})}}/>
              <Link to={RailCreateRoute} className="btn btn-sm btn-primary">
                <i className="la la-plus"/>
                Добавить
              </Link>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <DataTable columns={columns} search={query} url={`/api/railways/`}/>
        </PortletBody>
      </Portlet>

    </>
  );
};

export default RailwaysList;
