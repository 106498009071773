import React, {useEffect, useState} from "react";
import _ from "lodash";
import moment from "moment/moment";
import {connect} from "react-redux";
import {
  StartProcess
} from "../../../../redux/actions/base";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import BlockUi from "@availity/block-ui";
import {Table} from "react-bootstrap";
import Select from "react-select";
import {ReviseItemGetRoute} from "../../../routesConstants";
import {Link} from "react-router-dom";
import AddReviseItem from "../../components/addReviseItem";
import axios from "../../../../helpers/axios";
import sayError from "../../../../helpers/sayError";
import SearchSelect from "../../../../components/searchSelect";
import {useController, useForm, useWatch} from "react-hook-form";
import {Helmet} from "react-helmet";

export const weeks = [
  [1, 7],
  [8, 15],
  [16, 23],
  [24, 32]
];
const quarterNow = month => {
  if (month < 4) {
    return 1;
  } else if (month > 3 && month < 7) {
    return 2;
  } else if (month > 6 && month < 10) {
    return 3;
  } else {
    return 4;
  }
};
export const typeList = {
  "aruana-kayar": "Aruana - Kayar",
  "silk-kayar": "Silk Tour - Kayar",
  "iata-aruana": "Iata - Aruana",
  "kayar-site": "Kayar - Site",
  "yana-kayar": "Kayar - Yana(Акт)"
};
const ReviseSortPage = props => {
  const { control,
    getValues,
    watch} = useForm()
  const now = +moment();
  const [loading, setLoading] = useState(false);
  const [revises, setRevises] = useState();
  const [activeYear, setActiveYear] = useState(+moment().format("YYYY"));
  const [activeQuarter, setActiveQuarter] = useState(
    quarterNow(+moment().format("M"))
  );
  const [render, setRender] = useState();
  const [refetch, setRefetch]= useState(Math.random)
  const onFocus = () => {
    loadRevise();
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    // Calls onFocus when the window first loads
    // onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  useEffect(() => {
    loadRevise();
  }, [useWatch({control, name: ['year', 'quarter']}), refetch]);
  useEffect(() => {
    generateRender(revises);
  }, [revises]);

  const generateRender = revises => {
    console.log("generateRender");
    const months = [];
    const activeYear = getValues().year
    const activeQuarter = getValues().quarter
    for (let i = 0; i < 3; i++) {
      const newDate = moment(activeYear, "YYYY")
        .quarter(activeQuarter)
        .add(i, "month");

      if (moment(newDate).isAfter(now, "M")) {
        break;
      } else {
        console.log('render', i)
        const currMonth = {
          month: newDate.format("MM"),
          weeks: []
        };
        for (let f = 0; f < 4; f++) {
          const startDay = weeks[f][0];
          const endDay =
            f !== 3
              ? weeks[f][1]
              : +moment(`${activeYear}${currMonth.month}`, "YYYYMM")
                .endOf("month")
                .format("DD");
          if (moment(`${activeYear}${currMonth.month}${endDay<10 ? 0 : ""}${endDay}`).isAfter(now)) {
            break;
          } else {
            const revise = _.find(revises, u => {
              return (
                moment
                  .utc(u.startDate)
                  .isAfter(
                    moment(
                      `${activeYear}${currMonth.month}${startDay}`,
                      "YYYYMMD"
                    )
                  ) &&
                moment
                  .utc(u.endDate)
                  .isBefore(
                    moment(
                      `${activeYear}${currMonth.month}${endDay}`,
                      "YYYYMMD"
                    ).endOf("day")
                  )
              );
            });
            console.log("revise", revise);
            const week = {
              start: startDay,
              end: endDay,
              createdBy: revise && revise.createdBy,
              createdDate: revise && revise.createdDate,
              comment: revise && revise.comment,
              id: revise && revise.id,
              checked: revise?.checked || false,
              reports: {
                "aruana-kayar":
                  (revise &&
                    _.find(revise.items, {reviseType: "aruana-kayar"})),
                "silk-kayar":
                  (revise &&
                    _.find(revise.items, {reviseType: "silk-kayar"})),
                "iata-aruana":
                  (revise &&
                    _.find(revise.items, {reviseType: "iata-aruana"})),
                "kayar-site":
                  (revise &&
                    _.find(revise.items, {reviseType: "kayar-site"})),
                "yana-kayar":
                  (revise &&
                    _.find(revise.items, {reviseType: "yana-kayar"})),
              }
            };
            currMonth.weeks.push(week);
          }
        }
        months.push(currMonth);
      }
    }
    setRender(months);
    console.log("months", months);
  };

  const loadRevise = () => {
    setLoading(true);
    props.StartProcess(
      "/api/racoon/revise",
      ({data}) => {
        console.log("revise", data);
        setRevises(data.items);

        setLoading(false);
      },
      getValues()
    );
  };
  const fastUpdate = (id) =>{
    setLoading(true)
    axios.post('/api/racoon/revise/item/fastUpdate/'+id)
      .then(()=>loadRevise())
      .catch(sayError)
      .finally(()=>setLoading(false))
  }
  const createArrayYers = () => {
    const start = 2022;
    const end = +moment().format("YYYY");
    const arr = [];
    for (let i = start; i <= end; ++i) {
      arr.push({value: i, label: i});
    }
    return arr;
  };
  const yearsList = createArrayYers();
  const quarter = [
    {value: 1, label: "1 Квартал"},
    {value: 2, label: "2 Квартал"},
    {value: 3, label: "3 Квартал"},
    {value: 4, label: "4 Квартал"}
  ];
  const colColor = (nums, count, checked) => {
    if (nums === undefined) {
      nums = []
    }
    if(checked){
      return "table-success";
    } else if (nums.length !== count) {
      return "table-danger";
    } else if (nums.reduce((a, b) => a + b, 0) === 0) {
      return "table-success";
    } else {
      return "table-warning";
    }
  };
  const {
    field: { onChange: yearOnChange, value: yearValue }
  } = useController({
    name: `year`,
    control,
    rules: { required: true },
    defaultValue: +moment().format("YYYY")
  });
  const {
    field: { onChange: quarterOnChange, value: quarterValue }
  } = useController({
    name: `quarter`,
    control,
    rules: { required: true },
    defaultValue: quarterNow(moment().format("M"))
  });
  console.log('getValues',getValues())
  return (
    <>
      <Helmet>
        <title>Недельные сверки</title>
      </Helmet>
      <Portlet>
        <PortletHeader
          title={"Список отчетов"}
          toolbar={
            <PortletHeaderToolbar>
              {/*<form>*/}
                <SearchSelect
                  onChange={yearOnChange}
                  defaultValue={yearValue}
                  array={yearsList}/>
                <SearchSelect
                  onChange={quarterOnChange}
                  defaultValue={quarterValue}
                  array={quarter}/>
              {/*</form>*/}
              {/*<div style={{width: "200px", marginRight: "10px"}}>*/}
              {/*  <Select*/}
              {/*    options={yearsList}*/}
              {/*    onChange={val => {*/}
              {/*      setActiveYear(val.value);*/}
              {/*    }}*/}
              {/*    defaultValue={_.find(yearsList, {value: activeYear})}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div style={{width: "200px", marginRight: "10px"}}>*/}
              {/*  <Select*/}
              {/*    options={quarter}*/}
              {/*    onChange={val => {*/}
              {/*      setActiveQuarter(val.value);*/}
              {/*    }}*/}
              {/*    defaultValue={_.find(quarter, {value: `${activeQuarter}`})}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<CreateReviseButton/>*/}
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <BlockUi blocking={loading}>
            <Table striped bordered hover>
              <tbody>
              {render &&
                render.map((month, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          colSpan={3}
                          style={{textTransform: "capitalize"}}
                        >
                          {moment(month.month, 'M', 'ru').format('MMMM')}
                        </td>
                      </tr>
                      {month.weeks.map((week, index) => {
                        return (
                          <React.Fragment key={index}>
                            {Object.keys(week.reports).map((key, i) => {
                              const checkedWeekFalse = Object.keys(week.reports).map(u=>(week.reports[u]?.checked)).includes(false)
                              const checkedWeekUndefined = Object.keys(week.reports).map(u=>(week.reports[u]?.checked)).includes(undefined)
                              const checkedWeek = checkedWeekFalse || checkedWeekUndefined
                              return (
                                <tr>
                                  {i === 0 ? (
                                    <td
                                      rowSpan={5}
                                      className={colColor(
                                        Object.keys(week.reports)
                                          .map(u => week.reports[u]?.dataItems?.slice(1))
                                          .flat(),
                                        9, !checkedWeek
                                      )}
                                    >
                                      {week.start} - {week.end}
                                      <br/>
                                      {/*{week.id ? (*/}
                                      {/*  <>*/}
                                      {/*    <Link to={ReviseGetRoute + week.id} target={"_blank"}>*/}
                                      {/*      перейти*/}

                                      {/*    </Link>*/}
                                      {/*    <br/>*/}
                                      {/*    от{" "}*/}
                                      {/*    {moment(week.createdDate).format(*/}
                                      {/*      "DD.MM HH:mm"*/}
                                      {/*    )}*/}
                                      {/*    <br/>*/}
                                      {/*    {week.createdBy}*/}
                                      {/*  </>*/}
                                      {/*) : null}*/}
                                    </td>
                                  ) : null}
                                  <td
                                    className={colColor(
                                      week.reports[key]?.dataItems?.slice(1),
                                      3, week.reports[key]?.checked
                                    )}
                                  >
                                    {week.reports[key]
                                      ? <div className={'d-flex justify-content-between align-items-center'}>
                                      <Link
                                      to={ReviseItemGetRoute + week.reports[key].id}
                                      className={'w-75'}
                                      target={"_blank"}
                                      >
                                        {typeList[key]}
                                      </Link>
                                      <i className="fa fa-refresh pointer"  onClick={()=>fastUpdate(week.reports[key].id)}/>

                                      </div> :
                                      <AddReviseItem type={key} reload={()=>setRefetch(n=>Math.random()*n)}
                                                     targetWeek={index+1}
                                                     targetYear={getValues().year}
                                                     targetMonth={month.month}/> }
                                  </td>
                                  <td
                                    className={colColor(
                                      week.reports[key]?.dataItems?.slice(1),
                                      3, week.reports[key]?.checked
                                    )}>
                                    {week.reports[key]
                                      ? <>{moment(week.reports[key].createdDate).format(
                                        "DD.MM.YY HH:mm"
                                      )} {week.reports[key].createdBy}</>
                                      : ""}
                                  </td>
                                  <td
                                    className={colColor(
                                      week.reports[key]?.dataItems?.slice(1),
                                      3, week.reports[key]?.checked
                                    )}
                                  >
                                    {week.reports[key]
                                      ? week.reports[key].dataItems.join("/")
                                      : ""}
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </BlockUi>
        </PortletBody>
      </Portlet>
    </>
  );
};
export default connect(() => ({}), {StartProcess})(ReviseSortPage);
