import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { useParams } from "react-router-dom";
import { TripItemListRoute } from "../../../routesConstants";
import HotelBlock from "../components/hotelBlock";
import { useForm } from "react-hook-form";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../components/Portlet";
import { Button, Col, Row } from "react-bootstrap";
import TransferBlock from "../components/transferBlock";
import EtcBlock from "../components/etcBlock";
import TicketBlock from "../components/ticketBlock";
import LoadingButton from "../../../base/components/loadingButton";
import {Helmet} from "react-helmet";

const TripItemEditPage = props => {
  const [tripItem, setTripItem] = useState(null);
  const [loader, setLoader] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const { id } = useParams();

  const formController = useForm({ defaultValues: { paxes: [], item: {} } });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    formState,
    getValues,
    reset,
    setValue
  } = formController;

  // const setNewTrip = (tripModel) => {

  //   tripModel.paxes = trip.users;
  //   tripModel.item = trip.tickets;
  //   console.log('superNEwModel ', tripModel)
  //   setTrip(tripModel)
  // }
  useEffect(() => {
    if (tripItem === null) {
      setLoader(true);
      props.GetItem("/api/trips/item", id, res => {
        console.log('editdata', res.data)
        setTripItem(res.data);
        reset(res.data);
        setLoader(false);
      });
    }
  }, [tripItem, id, props, reset]);
  const callback = (values, e) => {
    if (Object.keys(errors).length === 0) {
      setFormLoading(true);
      props.UpdateItem(
        "/api/trips/item/" + id,
        values,
        () => {
          setFormLoading(false);
        },
        TripItemListRoute
      );
    }
    e.preventDefault();
  };
  // const deleteAction = () => {
  //   setLoader(true);
  //   props.DeleteItem('/api/trips/' + id, () => {}, TripListRoute)
  // }
  const getForm = () => {
    if (tripItem.item.type === "hotel") {
      return <HotelBlock formController={formController} hotel={tripItem} />;
    } else if (tripItem.item.type === "transfer") {
      return (
        <TransferBlock formController={formController} transfer={tripItem} />
      );
    } else if (tripItem.item.type === "etc") {
      return <EtcBlock formController={formController} etc={tripItem} />;
    } else if (tripItem.item.type === "ticket") {
      return (
        <TicketBlock
          formController={formController}
          ticket={tripItem.item ? tripItem.item : tripItem}
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Элемент поездки</title>
      </Helmet>
      {loader ? null : (
        <Row>
          <Col xs={9}>
            <Portlet>
              <PortletHeader title={"Редактирование элемента"} />
              <PortletBody>
                <form
                  onSubmit={handleSubmit(callback, () =>
                    alert("Ошибка при заполнении формы")
                  )}
                  autoComplete="off"
                >
                  {getForm()}
                  <br />
                  <br />
                  <Row>
                    <Col xs={12}>
                      <Button
                        variant={"default"}
                        onClick={() => {
                          console.log(getValues());
                        }}
                      >
                        Данные
                      </Button>{" "}
                      <LoadingButton
                        loading={formLoading}
                        title={"Сохранить"}
                        type={"submit"}
                      />
                    </Col>
                  </Row>
                </form>
              </PortletBody>
            </Portlet>
          </Col>
          <Col xs={3}>
            <Portlet>
              <PortletHeader title={'Общая информация'}/>
              <PortletBody>
                Создано: <br/>
                Кем: {tripItem.item.createdBy}<br/>
                Когда: {tripItem.item.createdAt}
              </PortletBody>
            </Portlet>
          </Col>
        </Row>
      )}
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  TripItemEditPage
);
