import React from 'react';
import moment from "moment/moment";
import {Table} from "reactstrap";
import {Form} from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";
import {Link} from "react-router-dom";

const OsvTable = ({tableData,start, end,hideMinus,setHideMinus,payment }) => {
  const renderMin = (number) =>{
    const standart = {textAlign:'end'}
    return number < 0 ? {color:'red',...standart}:{...standart}
  }
  const renderHeaderRow = (row, color, cl) => {
    return <tr style={{backgroundColor:color}} className={cl}>
      <td>{row.name}</td>
      <td style={renderMin(row.totalProd)} >{numberFormat(row.totalProd)}</td>
      <td style={renderMin(row.totalStDeb)}>{numberFormat(row.totalStDeb)}</td>
      <td style={renderMin(row.totalStCr)}>{numberFormat(row.totalStCr)}</td>
      <td style={renderMin(row.totalFlowDeb)}>{numberFormat(row.totalFlowDeb)}</td>
      <td style={renderMin(row.totalFlowCr)}>{numberFormat(row.totalFlowCr)}</td>
      <td style={renderMin(row.totalEndDeb)}>{numberFormat(row.totalEndDeb)}</td>
      {payment && <td style={renderMin(row.needPay)}>{numberFormat(row.needPay)}</td>}
    </tr>
  }
  return (
    <div>

      <div className={'tableFixOnlyHead'}>
          <div id="printarea">
            <div id={'onlyPrint'} style={{display: 'none'}}>
              <h4>ОСВ за период {start.slice(0, 5)} - {end}</h4>
              <h6>СФормирован: {moment().format('DD.MM.YYYY HH:mm')}</h6>
            </div>
            <Table bordered size={'sm'}>
              <thead>
              <tr>
                <th rowSpan={2}>
                  <Form.Label className={'d-flex align-items-center gap-2'} id={"notVisible"}>
                    <input
                      checked={hideMinus}
                      onChange={() => setHideMinus(!hideMinus)}
                      type={"checkbox"}
                    />{" "}
                    Скрыть минусы
                  </Form.Label>
                  Контрагент
                </th>
                <th rowSpan={2}>Ком.прод</th>
                <th colSpan={2}>Сальдо нач</th>
                <th colSpan={2}>Обороты</th>
                <th>Сальдо кон</th>
                {payment &&<th rowSpan={2}>Должен</th>}
              </tr>
              <tr>
                <th>Дебет</th>
                <th>Кредит</th>
                <th>Дебет</th>
                <th>Кредит</th>
                <th>Дебет</th>
              </tr>
              </thead>
              <tbody>
              {tableData.map((agent, index) => {
                return <React.Fragment key={index}>
                  {renderHeaderRow(agent, "#cfe2f3", 'blue')}
                  {agent.table.map((typeFace, i) => {
                    return <React.Fragment key={i}>
                      {renderHeaderRow(typeFace, "#d9ead3", 'green')}
                      {typeFace.table.map((row, indx) => {
                        return <tr key={indx}>
                          <td>{row.counterparty.name}</td>
                          <td style={renderMin(row.commentProd)}>{numberFormat(row.commentProd)}</td>
                          <td style={renderMin(row.startDeb)}>{numberFormat(row.startDeb)}</td>
                          <td style={renderMin(row.startCr)}>{numberFormat(row.startCr)}</td>
                          <td style={renderMin(row.flowDeb)}>{numberFormat(row.flowDeb)}</td>
                          <td style={renderMin(row.flowCr)}>{numberFormat(row.flowCr)}</td>
                          <td style={renderMin(row.endDeb)}>
                            <Link to={{
                              pathname: "/salesRegister/create",
                              search: `?start=${start}&end=${end}&id=${row.counterparty.id}`
                            }}
                                  target={'_blank'}>
                              {numberFormat(row.endDeb)}
                            </Link>

                          </td>
                          {payment && <td style={renderMin(row.needPay)}>{numberFormat(row.flowDeb-row.flowCr)}</td>
                          }
                        </tr>
                      })}
                    </React.Fragment>
                  })}
                </React.Fragment>
              })}
              </tbody>
            </Table>
          </div>
        </div>
    </div>
  );
};

export default OsvTable;
