import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import HotelBlock from "../components/hotelBlock";
import { useForm } from "react-hook-form";
import {
  etcTemplate,
  hotelTemplate,
  transferTemplate
} from "../../trip/components/tripFormParts/helpers/templates";
import { TripItemListRoute } from "../../../routesConstants";
import { CreateItem } from "../../../../redux/actions/base";
import TransferBlock from "../components/transferBlock";
import EtcBlock from "../components/etcBlock";
import TicketBlock from "../components/ticketBlock";
import BookingComButton from "../../components/BookingComButton";
import qs from "query-string";
import {useLocation} from "react-router";
import {Helmet} from "react-helmet";

const TripItemCreatePage = props => {
  const location = useLocation()
  const searchParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  console.log("searchParams", searchParams);
  const [type, setType] = useState(searchParams.type);
  const [defaultValue, setDefaultValue] = useState({ paxes: [], item: {public: true} });
  const formController = useForm({ defaultValues: defaultValue });
  const {
    formState,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    unregister,
    resetField,
    getValues,
    reset,
    setValue
  } = formController;
  const createCallback = (data, e) => {
    props.CreateItem("/api/trips/item", data, () => {}, TripItemListRoute);
  };
  console.log('defaultValueTEST',defaultValue)
  const getForm = () => {
    if (type === "hotel") {
      console.log("defaultValue", defaultValue);
      return (
        <HotelBlock formController={formController} hotel={defaultValue} />
      );
    } else if (type === "transfer") {
      return (
        <TransferBlock
          formController={formController}
          transfer={defaultValue}
        />
      );
    } else if (type === "etc") {
      return <EtcBlock formController={formController} etc={defaultValue} />;
    } else if (type === "ticket") {
      const getObjectId = (
        m = Math,
        d = Date,
        h = 16,
        s = s => m.floor(s).toString(h)
      ) =>
        s(d.now() / 1000) +
        " ".repeat(h).replace(/./g, () => s(m.random() * h));
      return (
        <TicketBlock
          formController={formController}
          ticket={{ pax: { tempId: getObjectId() }, item: {} }}
        />
      );
    }
  };
  useEffect(() => {
    console.log("defaultValue", defaultValue);
    // unregister("item");
    // resetField("item");
    reset(defaultValue);
  }, [defaultValue]);
  const selectType = selectedType => {
    if (selectedType === "hotel") {
      reset({ defaultValues: { paxes: [{}], item: { ...hotelTemplate, public: true } } });
    } else if (selectedType === "transfer") {
      reset({ defaultValues: { paxes: [{}], item: { ...transferTemplate, public: true } } });
    } else if (selectedType === "etc") {
      reset({ defaultValues: { paxes: [{}], item: { ...etcTemplate, public: true } } });
    } else if (selectedType === "ticket") {
      reset({ defaultValues: { pax: {}, item: {public: true} } });
    }
    setType(selectedType);
  };
  const typeNames = {
    hotel: "отель",
    transfer: "трансфер",
    etc: "разное",
    ticket: "билет"
  };
  return (
    <>
      <Helmet>
        <title>Элемент поездки</title>
      </Helmet>
      <div className={"kt-grid__item"}>
        <Portlet>
          <PortletHeader
            title={"Создание элемента " + (type ? typeNames[type] : "")}
            toolbar={
              <PortletHeaderToolbar>
                {type === "hotel" && (
                  <BookingComButton
                    setter={item => {
                      console.log("item", item);
                      setValue("item", item);
                      setDefaultValue({ paxes: defaultValue.paxes, item });
                      // reset({
                      //   defaultValue: { paxes: defaultValue.paxes, item }
                      // });
                    }}
                  />
                )}
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <form
              onSubmit={handleSubmit(createCallback, () =>
                alert("Ошибка при заполнении формы")
              )}
              autoComplete="off"
            >
              {!type ? (
                <Row>
                  <Col xs={12}>
                    <p>Выберете тип создаваемого элемента</p>
                    <Button onClick={() => selectType("hotel")}>
                      Отель
                    </Button>{" "}
                    <Button onClick={() => selectType("transfer")}>
                      Трансфер
                    </Button>{" "}
                    <Button onClick={() => selectType("ticket")}>
                      Перелет
                    </Button>{" "}
                    <Button onClick={() => selectType("etc")}>Разное</Button>
                  </Col>
                </Row>
              ) : (
                getForm()
              )}
              <br />
              <br />
              <Row>
                <Col xs={12}>
                  <Button
                    variant={"default"}
                    onClick={() => {
                      console.log(getValues());
                    }}
                  >
                    Данные
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      console.log(errors);
                    }}
                    variant={"secondary"}
                  >
                    Ошибки
                  </Button>{" "}
                  <Button variant={"primary"} type={"submit"}>
                    Сохранить
                  </Button>{" "}
                </Col>
              </Row>
            </form>
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

export default connect(() => ({}), { CreateItem })(TripItemCreatePage);
