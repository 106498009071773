import React, {useState} from "react";
import {connect} from "react-redux";
import {AirBookingCreateRoute, AirBookingEditRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import {Link} from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import Button from 'react-bootstrap/Button';
import {useNavigate} from "react-router";
import AmadeusButton from "../components/amadeusButton";
import ListQueryForm from "../ListQueryForm";
import {Helmet} from "react-helmet";

const AirlineList = (props)=>{
  const columns = [
    {
      Header: 'Код бронирования',
      accessor: 'bookingNumber',
      Cell: ({value, row}) => <Link to={AirBookingEditRoute + row.original._id } >{value}</Link>
    },
    {
      Header: 'Пассажиры',
      accessor: 'paxes',
      Cell: ({value, row}) => <span>{value.map((pax,index)=><span key={index}>{pax.lastName} {pax.firstName}<br/></span>)}</span>
    },
    {
      Header: 'Маршрут',
      accessor: 'route',
    },
    {
      Header: 'Дата выписки',
      accessor: 'issueDate',
      Cell: ({value, row}) => <span>{value}</span>
    },
    {
      Header: 'Дата создания',
      accessor: 'createdAt',
      Cell: ({value, row}) => <span>{value}</span>
    },
    {
      Header: "Опл",
      accessor: "paid",
      Cell: ({ value, row }) => (
        <div className={'d-flex justify-content-center'}>
          {!row.original.issuedWithoutDocs ? <i className="fa fa-file-text " style={{color:'rgba(107,112,161,0.8)'}} ></i> : value ? (
            <i className={"fa fa-check-circle text-success"} />
          ) : (
            <i className={"fa fa-times-circle text-danger"} />
          )}
        </div>
      )
    },
  ]

  const [query, setQuery] = useState({});
  const navigate = useNavigate()
  return <>
    <Helmet>
      <title>БСО</title>
    </Helmet>
        <div>
          <Portlet>
            <PortletHeader
              title={<AmadeusButton/>
              }
              toolbar={
                <PortletHeaderToolbar>
                  <div className={'d-flex gap-2 align-items-center'}>
                    <ListQueryForm query={query} setQuery={setQuery}/>
                    <Link to={AirBookingCreateRoute} className="btn btn-sm btn-primary">
                      <i className="la la-plus"/>
                      Добавить
                    </Link>
                  </div>


                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <DataTable columns={columns} search={query} url={`/api/air/airBooking`}/>
            </PortletBody>
          </Portlet>
        </div>
  </>
}


export default connect(null)(AirlineList)
