import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import CityForm from "../components/cityForm";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { CityListRoute } from "../../../routesConstants";
import { useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { Dropdown, Row, Col } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Process from "../../../../components/process";
import {Helmet} from "react-helmet";

const CityEditPage = props => {
  const [city, setCity] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    console.log("effect");
    if (city === null) {
      setLoader(true);
      props.GetItem("/api/locations/cities", id, res => {
        setCity(res.data.data);
        setLoader(false);
      });
    }
  }, [city, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem("/api/locations/cities/" + id, values, cb, CityListRoute);
  };

  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem("/api/locations/cities/" + id, () => {}, CityListRoute);
  };
  const citySyncCb = d => {
    setCity(d.city);
  };
  return (
    <>
      <Helmet>
        <title>{city?.name || "Город"}</title>
      </Helmet>
      <Row>
        <Col lg={6}>
          <Portlet>
            <PortletHeader
              title={"Редактирование города"}
              toolbar={
                <PortletHeaderToolbar>
                  <BlockUi blocking={loader}>
                    <DropdownButton variant="outline-danger" title="Удалить">
                      <Dropdown.Item as="button" onClick={deleteAction}>
                        Да! Удалить
                      </Dropdown.Item>
                    </DropdownButton>
                  </BlockUi>
                </PortletHeaderToolbar>
              }
            />
            <BlockUi blocking={loader}>
              <CityForm onSubmit={callback} data={city} />
            </BlockUi>
          </Portlet>
        </Col>
        <Col lg={6}>
          <Process
            desc={"Достает координаты и русское название"}
            title={"Синхронизация c Places"}
            link={`/api/locations/cities/syncCity/${id}`}
            cb={citySyncCb}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(CityEditPage);
