import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import moment from "moment";
import LoadingButton from "../../base/components/loadingButton";
import {useForm} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import axios from "../../../helpers/axios";
import {Table} from "reactstrap";
import numberFormat from "../../../helpers/numberFormat";
import _ from 'lodash'
import {Form, Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import OsvTable from "../components/osvTable";

const OsvPage = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [tableData, setTableData] = useState()
  const [paymentData, setPaymentData] = useState()
  const [hideMinus, setHideMinus] = useState(true)
  const [tabIndex, setTabIndex] = useState("main");
  const formController = useForm()
  const {register,getValues, handleSubmit, formState:{errors}} = formController

  const createTable = (data) =>{
    const generateTotal = (arr) =>{
      return {
        totalProd : _.sumBy(arr,'commentProd'),
        totalStDeb : _.sumBy(arr,'startDeb'),
        totalStCr : _.sumBy(arr,'startCr'),
        totalFlowDeb : _.sumBy(arr,'flowDeb'),
        totalFlowCr : _.sumBy(arr,'flowCr'),
        totalEndDeb : _.sumBy(arr,'endDeb'),
        totalEndCr : _.sumBy(arr,'endCr'),
      }
    }
    const finalGroup = []
    const paymentTable = []
    Object.keys(data).forEach(key =>{
      let info = data[key]
      if(hideMinus){
        info = _.filter(data[key], (r)=>(r.endDeb>0))
      }
      const groupByFace = _.groupBy(info, {counterparty:{jur:true}})

      finalGroup.push({
        name: key === "true" ? "Ярослав" : "Офис",
        ...generateTotal(info),
        table:Object.keys(groupByFace).map(key=> {
          const filtered = _.sortBy(_.filter(groupByFace[key], (t) => (t.endDeb !== 0)), (t) => (t.endDeb * -1))
          return({
            name: key === "true" ? "Юр.лицо" : "Физ.лицо",
            ...generateTotal(filtered),
            table: filtered
          })
        })
      })
      info= _.filter(info, (t) => (t.flowDeb - t.flowCr > 0))
      paymentTable.push({
        name: key === "true" ? "Ярослав" : "Офис",
        needPay: _.sumBy(info, (t) => (t.flowDeb - t.flowCr)),
        ...generateTotal(info),
        table:Object.keys(groupByFace).map(key=> {
          const filtered = _.sortBy(_.filter(groupByFace[key], (t) => (t.flowDeb - t.flowCr > 0)), (t) => (t.endDeb * -1))
          return({
            name: key === "true" ? "Юр.лицо" : "Физ.лицо",
            ...generateTotal(filtered),
            needPay:  _.sumBy(filtered, (t) => (t.flowDeb - t.flowCr)),
            table: filtered
          })
        })
      })
    })
    setTableData(finalGroup)
    setPaymentData(paymentTable)
  }
  const onSubmit = (data) =>{
    setLoading(true)
    document.title = `ОСВ за ${data.start} ${data.end}`
    axios.post('/api/fromOneC/osv',data)
      .then(({data})=>{
        setData(data.data)
        createTable(data.data)
      })
      .finally(()=>setLoading(false))
  }


  useEffect(() => {
    if(data){
      createTable(data)
    }
  }, [hideMinus]);
  return (
    <>
      <Helmet>
        <title>ОСВ</title>
      </Helmet>
      <div id={'main'}>
        <Portlet>
          <PortletHeader title={'ОСВ'} toolbar={<PortletHeaderToolbar>
            <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-center gap-2'}>
              <CustomDatePicker formController={formController}
                                label={"Дата c"}
                                value={moment().subtract(7, 'days').format('DD.MM.YYYY')}
                                name={`start`}
                                rules={{required: true}}/>
              <CustomDatePicker formController={formController}
                                label={"Дата по"}
                                value={moment().format('DD.MM.YYYY')}
                                name={`end`}
                                rules={{required: true}}/>
              <FormHookInput
                register={register(`account`, {required: true, maxLength: 4, minLength: 4})}
                defaultValue={1210}
                type={'number'}
                label={'Счет'}
                errors={errors}
              />
              <LoadingButton loading={loading} title={'Получить'}/>

            </form>
          </PortletHeaderToolbar>}/>

            {tableData && <Tabs
              variant="underline"
              id="controlled-tab-example"
              activeKey={tabIndex}
              className={'ml-2'}
              onSelect={key => {
                setTabIndex(key)
              }}
            >
              <Tab eventKey="main" title="Осв" >
                <OsvTable tableData={tableData}
                          hideMinus={hideMinus}
                          setHideMinus={setHideMinus}
                          end={getValues().end}
                          start={getValues().start}/>
              </Tab>
              <Tab title={'Оплаты'} eventKey={'payment'}>
                <OsvTable tableData={paymentData}
                          hideMinus={hideMinus}
                          payment
                          setHideMinus={setHideMinus}
                          end={getValues().end}
                          start={getValues().start}/>
              </Tab>
            </Tabs>
            }

        </Portlet>
      </div>


    </>
  );
};

export default OsvPage;
