import React, {useState} from 'react';
import OfferForm from "../components/offerForm";
import {connect} from "react-redux";
import {CreateItem} from "../../../../redux/actions/base";
import {OfferListRoute, TripItemListRoute, TripListRoute} from "../../../routesConstants";
import axios from "../../../../helpers/axios";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";


const OfferCreatePage = ({CreateItem}) =>{
  const navigate = useNavigate()

  const callback = (values, cb)=>{
    console.log('callback', values)
    axios
      .put('/api/trips/offer', values)
      .then(()=>navigate(OfferListRoute))
      .catch(e=>cb())
    // CreateItem('/api/trips/offer', values, cb, OfferListRoute )
  }
  const tripTempData = {
    groups: [],
    descriptions: [],
    photos: [],
    documents: [],
    visibleToAccounts: [],
    countries: [],
    public: true
  }
  return <>
    <Helmet>
      <title>Предложение</title>
    </Helmet>
    <OfferForm onSubmit={callback}
              data={tripTempData}
              title={"Создание предложения"}/>

  </>
}

export default connect(null, {CreateItem})(OfferCreatePage)
