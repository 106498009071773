import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Button, Form, Col, Table, Row } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { StartProcess } from "../../../../redux/actions/base";
import FormHookDatePicker from "../../../base/components/forms/formHookDatePicker";
import BlockUi from "@availity/block-ui";
import {useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import ReportHeadSearch from "../components/reportHeadSearch";
import {Helmet} from "react-helmet";



const AirBookingReportPage = ({ StartProcess }) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [query, setQuery] = useState({targetDate: new Date()})
  const formController = useForm({defaultValues: {targetDate: new Date()}})
  const {control, setValue, values , watch, getValues} = formController
  //const watcher =
  useEffect(() => {
    console.log('getValues', getValues())
    loadTickets();
  }, [ query]);
  const loadTickets = () => {
    setLoading(true);
    StartProcess(
      "/api/air/airBooking/report",
      res => {
        console.log("res", res);
        if (res.data.success) {
          setTickets(res.data.tickets);
        }
        setLoading(false);
      },
      {targetDate: moment(query.targetDate).format('DD.MM.YYYY')}
    );
  };

  return (
    <>
      <Helmet>
        <title>Отчет</title>
      </Helmet>
      <Row>
        <Col xs={12}>
          <Button
            onClick={() => setShowLinks(!showLinks)}
            variant={showLinks ? "primary" : "secondary"}
          >
            Показать ссылки
          </Button>
        </Col>
      </Row>
      <br />
      <div className={"kt-grid__item"}>
        <Portlet>
          <BlockUi blocking={loading}>
            <PortletHeader
              title={"Отчет по билетам"}
              toolbar={
                <PortletHeaderToolbar>
                  {/*<DatePicker*/}
                  {/*  selected={query.targetDate}*/}
                  {/*  autoComplete='off'*/}
                  {/*  className={"form-control"}*/}
                  {/*  dateFormat={'dd.MM.yyyy'}*/}
                  {/*  onChange={date => {*/}
                  {/*    console.log('date', moment(date).format('DD.MM.YYYY HH:mm'))*/}
                  {/*    setQuery(q => ({...q, targetDate: new Date(date)}))*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <ReportHeadSearch cb={setQuery} query={query}/>
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <Table bordered hover>
                <thead>
                <tr>
                  <th>Дата выписки</th>
                  <th>Дата вылета</th>
                  <th>Номер билета</th>
                  <th>Пассажир</th>
                  <th>Маршрут</th>
                  <th>Тариф</th>
                  <th>Таксы</th>
                  <th>Штраф</th>
                  <th>Сумма</th>
                  <th>КРС Покупателя</th>
                  <th>КРС Поставщика</th>
                  <th>КРС Kayar</th>
                  <th>Контрагент</th>
                  <th>ИТОГО</th>
                  <th>Коммент</th>
                  <th>Выписал</th>
                </tr>
                </thead>
                <tbody >
                {tickets.map((supplier, i) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <b>
                            {supplier.supplier}
                          </b>
                        </td>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                      </tr>
                      {/*{Object.keys(supplier.tickets).map((k, i) => (*/}
                      {/*  <React.Fragment key={i}>*/}
                      {/*    <tr>*/}
                      {/*      <td colSpan={12}>*/}
                      {/*        <b>{moment.unix(k).format("DD MMM")}</b>*/}
                      {/*      </td>*/}
                      {/*    </tr>*/}
                      {/*    */}
                      {/*  </React.Fragment>*/}
                      {/*))}*/}
                      {supplier.tickets.map((ticket, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{ticket.issuedDate}</td>
                            <td>{ticket.flightDate}</td>
                            <td>
                              {showLinks ? (
                                  <a
                                    href={supplier.baseLink + ticket.bookingId}
                                    target={"_blank"}
                                  >
                                    {ticket.airlineNumber}{(supplier.supplier !== 'ЖД' && /[0-9]{3}/.test(ticket.airlineNumber)) ? '-' : supplier.supplier !== 'ЖД' && '/'}
                                    {ticket.ticketNumber}
                                    {ticket.refund ? "-В" : ""}
                                  </a>
                                ) :
                                <>{ticket.airlineNumber}{(supplier.supplier !== 'ЖД' && /[0-9]{3}/.test(ticket.airlineNumber)) ? '-' : supplier.supplier !== 'ЖД' && '/'}
                                  {ticket.ticketNumber}
                                  {ticket.refund ? "-В" : ""}
                                </>
                              }
                            </td>
                            <td>
                              {ticket.pax.lastName}{ticket.pax.firstName !== '' && `/${ticket.pax.firstName} ${ticket.pax.title}`}
                            </td>
                            <td>{ticket.route}</td>
                            <td>{ticket.fare}</td>
                            <td>{ticket.taxes}</td>
                            <td>{ticket.penalties}</td>
                            <td>{ticket.total}</td>
                            <td>{ticket.krsBuyer}</td>
                            <td>{ticket.krsSeller}</td>
                            <td>{ticket.krsKayar}</td>
                            <td>{ticket.counterparty}</td>
                            <td>{ticket.grandTotal}</td>
                            <td>{ticket.comment}</td>
                            <td>{ticket.agent}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                })}
                </tbody>
              </Table>
            </PortletBody>
          </BlockUi>
        </Portlet>
      </div>
    </>
  );
};

export default connect(null, { StartProcess })(AirBookingReportPage);
