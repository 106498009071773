import React from 'react';
import {Portlet, PortletHeader} from "../../../components/Portlet";
import CurrencyForm from "../components/currencyForm";
import {connect} from "react-redux";
import {CreateItem} from "../../../redux/actions/base";
import { CurrencyListRoute} from "../../routesConstants";
import {Helmet} from "react-helmet";


const CurrencyCreatePage = ({CreateItem}) =>{

  const callback = (values, cb)=>{
    CreateItem('/api/currencies', values, cb, CurrencyListRoute )
  }
  return <>
    <Helmet>
      <title>Валюта</title>
    </Helmet>
    <div className={"row"}>
      <div className="col-xl-6">
        <Portlet>
          <PortletHeader title={"Создание валюты"}/>
          <CurrencyForm onSubmit={callback}/>
        </Portlet>
      </div>
    </div>
  </>
}

export default connect(null, {CreateItem})(CurrencyCreatePage)
