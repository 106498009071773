import React, {useEffect, useState} from 'react';
import TouristServicesForm from "../components/TouristServicesForm";
import BlockUi from "@availity/block-ui";
import {serviceTemplate} from "../components/ServicesFields";
import {useSelector} from "react-redux";
import axios from "../../../helpers/axios";
import moment from "moment";
import {useNavigate} from "react-router";
import {TouristServicesListRoute} from "../../routesConstants";
import { v4 as uuidv4 } from 'uuid';
import sayError from "../../../helpers/sayError";
import _ from "lodash";
import {Helmet} from "react-helmet";

const TouristServicesCreate = () => {
  const { id } = useSelector(state => state.auth.user);
  const navigate = useNavigate()
  const [loader, setLoader]=useState(false)
  const [data, setData] = useState()
  const [potentialBuyers, setPotentialBuyers] = useState()
  const [fromParser, setFromParser] =useState(false)

  const tempData = {
    issueDate: moment().format('DD.MM.YYYY'),
    seller:process.env.REACT_APP_KAYAR_ID,
    buyer:'',
    code:'',
    message:'',
    issuedBy:id,
    public: true,
    issuedWithoutDocs: true,
    serviceItems: [serviceTemplate]
  }
  useEffect(() => {
    if(localStorage.getItem("items")){
      const orders = JSON.parse(localStorage.getItem("items"))
      axios.post('/api/touristServices/ratehawk/order', {orders})
        .then(({data})=>{
          console.log('tyt',data)
          const newInvoise = data.invoice
          const keys = ['seller','tourismType','purpose','docState','public']
          keys.forEach(key=>{
            newInvoise[key] = tempData[key]
          })
          setData(newInvoise)
          setPotentialBuyers(data.potentialBuyers)
          setFromParser(true)
        })
        .finally(()=>{
          localStorage.removeItem("items")
      })
    }else if(localStorage.getItem("servCopied")){
      setData(JSON.parse(localStorage.getItem("servCopied")))
      localStorage.removeItem("servCopied")
    } else {
      setData(tempData)
    }
  }, []);
  const onSubmit = (data) =>{
    setLoader(true)
    data.serviceItems.forEach((item)=>{
      item.startDate = moment(item.startDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
      item.endDate = moment(item.endDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
      if(item.idInOneC === ''){
        item.idInOneC = uuidv4()
      }
    })
    const sortingDate = _.sortBy(data.serviceItems, e=>moment(e.endDate).unix()).reverse()
    // data.issueDate = sortingDate[0].endDate
    data.issueDate = moment(data.issueDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')

    axios.put('/api/touristServices/', data)
      .then(({data})=>{
        navigate(TouristServicesListRoute)
        console.log('uploadstatus',data)
        if(typeof data.uploadResult === 'object'){
          window.alert("Загрузка прошла успешно!Но при выгрузке в 1с произошла ошибка. Обратитесь к тех.специалисту")
        }
      })
      .finally(()=>setLoader(false))
  }

  return (
    <>
      <Helmet>
        <title>Услуга</title>
      </Helmet>
      <BlockUi blocking={loader}>
        {data &&<TouristServicesForm onSubmit={onSubmit}
                                     data={data}
                                     fromParser={fromParser}
                                     potentialBuyers={potentialBuyers}/>}
      </BlockUi>
    </>
  );
};

export default TouristServicesCreate;
