import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Modal,
  Overlay,
  InputGroup,
  Row
} from "react-bootstrap";
import BlockUi from "@availity/block-ui";
import _ from "lodash";
import { Cropper } from "react-cropper";
import { Buffer } from "buffer";
import axios from "../../../helpers/axios";
import ApiService from "../../../services/apiService";
import sayError from "../../../helpers/sayError";
import axiosBase from 'axios'
import {noDispHeader} from "../../../helpers/errorTranslate";

const PhotosSelector = ({ allTypes, cb ,folder}) => {
  const cropperRef = useRef();
  const [show, setShow] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [image, setImage] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setImage(cropper.getCroppedCanvas().toDataURL())

  };
  const toTurn = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90).scale(-1,-1);
    // setImage(cropper.getCroppedCanvas().toDataURL())
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (image) {
      setShowCropper(true);
      setInputVal("");
    }
    setLoading(false);
  }, [image]);
  useEffect(() => {
    setInputVal("");
    setImage("");
    setShowCropper(false);
    setLoading(false);
  }, [show]);
  useEffect(() => {
    const handlePasteAnywhere = async event => {
      const clipboardItems = await navigator.clipboard.read();
      const blobOutput = await clipboardItems[0].getType("image/png");

      var fileReader = new FileReader();
      fileReader.onloadend = function() {
        setImage(fileReader.result);
      };
      fileReader.readAsDataURL(blobOutput);
    };
    if (show) {
      window.addEventListener("paste", handlePasteAnywhere);
    }
    return () => {
      window.removeEventListener("paste", handlePasteAnywhere);
    };
  }, [show]);

  const uploadDocs = async () => {
    setLoading(true)
    const cropper = cropperRef.current?.cropper;
    fetch(cropper.getCroppedCanvas().toDataURL())
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "File name.png",{ type: "image/png" })
        console.log(file)
        const data = new FormData();
        data.append("file", file)
        folder && data.append("folder", folder)
        axios.post("/api/misc/cloudStorageUpload",data,{headers: {...noDispHeader,
            'Content-Type':"application/x-www-form-urlencoded"}})
          .then((resp)=>{
            cb(resp.data.fileUrl);
            setShow(false);
            setImage(null);
            setShowCropper(false);
            setLoading(false);
          })
        // const resp = await ApiService.post("/api/misc/cloudStorageUpload", data);
        // console.log(resp);


      })

  }
  function getBase64(file, callback) {
    console.log("paste",file)
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      callback(reader.result);

    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  }

  const uploadByUrl = () => {
    console.log('url')
    axiosBase
      .get(inputVal, {
        responseType: "arraybuffer"
      })
      .then(response => {
        const path = Buffer.from(response.data, "binary").toString("base64");
        console.log("path", path);
        setImage("data:image/jpeg;base64," + path);
      })
      .catch(e => {
        console.log(e);
        alert("Bad path");
      });
  };

  return (
    <>
      <Button size={"sm"} onClick={handleShow}>
        <i className={"fa fa-plus"} />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <BlockUi blocking={loading}>
          <Modal.Header closeButton>
            <Modal.Title>Выбор фотографии</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {showCropper ? (
                <Col xs={12}>
                  <Cropper
                    src={image}
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={16 / 9}
                    guides={false}
                    // crop={onCrop}
                    viewMode={2}
                    autoCropArea={10}
                    ref={cropperRef}
                  />
                </Col>
              ) : (
                <Col xs={12}>
                  1 Способ - Выбор файла(ов)
                  <FormControl
                    placeholder="Выберите файл"
                    type={"file"}
                    {...(allTypes ? {} : { accept: ".webp,.jpg,.jpeg,.png" })}
                    onChange={text => {
                      getBase64(text.target.files[0], u => setImage(u));
                      setLoading(true);
                    }}
                  />
                  2 Способ - Ctrl+V <br />3 Способ - ссылка
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Url"
                      value={inputVal}
                      onChange={e => setInputVal(e.target.value)}
                    />
                    <Button
                      disabled={!inputVal}
                      variant="primary"
                      onClick={uploadByUrl}
                    >
                      Отправить
                    </Button>
                  </InputGroup>
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {showCropper ? (
              <>
                <Button variant="outline-primary" onClick={toTurn}>
                  Повернуть
                </Button>
                <Button variant="outline-primary" onClick={onCrop}>
                  Обрезать
                </Button>
                <Button variant="primary" onClick={uploadDocs}>
                  Загрузить
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Закрыть
              </Button>
            )}
          </Modal.Footer>
        </BlockUi>
      </Modal>
    </>
  );
};

export default PhotosSelector;

function retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
  if (pasteEvent.clipboardData == false) {
    if (typeof callback == "function") {
      callback(undefined);
    }
  }
  // retrive elements from clipboard
  var items = pasteEvent.clipboardData.items;

  if (items == undefined) {
    if (typeof callback == "function") {
      callback(undefined);
    }
  }
  // loop the elements
  for (var i = 0; i < items.length; i++) {
    // Skip content if not image
    if (items[i].type.indexOf("image") == -1) continue;
    // Retrieve image on clipboard as blob
    var blob = items[i].getAsFile();

    // Create an abstract canvas and get context
    var mycanvas = document.createElement("canvas");
    var ctx = mycanvas.getContext("2d");

    // Create an image
    var img = new Image();

    // Once the image loads, render the img on the canvas
    img.onload = function() {
      // Update dimensions of the canvas with the dimensions of the image
      mycanvas.width = this.width;
      mycanvas.height = this.height;

      // Draw the image
      ctx.drawImage(img, 0, 0);

      // Execute callback with the base64 URI of the image
      if (typeof callback == "function") {
        callback(mycanvas.toDataURL(imageFormat || "image/png"));
      }
    };

    // Crossbrowser support for URL
    var URLObj = window.URL || window.webkitURL;

    // Creates a DOMString containing a URL representing the object given in the parameter
    // namely the original Blob
    img.src = URLObj.createObjectURL(blob);
  }
}
