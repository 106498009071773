import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "../../../helpers/axios";
import RailWaisOrdersItemForm from "../components/RailWaisOrdersItemForm";
import {useForm} from "react-hook-form";
import Loader from "../../../components/loader";
import moment from "moment";
import LoadingButton from "../../base/components/loadingButton";
import {useNavigate} from "react-router";
import {RailListRoute} from "../../routesConstants";
import sayError from "../../../helpers/sayError";
import {Helmet} from "react-helmet";

const RailOrderPage = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(true)
  const navigate=useNavigate()
  const formController = useForm()
  const {handleSubmit,
    register,
    getValues,
  formState:{errors}} = formController
  const [data, setData] = useState()
  useEffect(() => {
    axios.get('/api/railways/'+id)
      .then(({data})=>{
        console.log('item', data)
        setData({...data.order, edit:true})
      })
      .finally(()=>setLoading(false))
  }, [id]);
  const onSubmit = (data) =>{
    setLoading(true)
    console.log('submit', data)
    axios.patch('/api/railways/'+id, data.order)
      .then(()=>{
        navigate(RailListRoute)
      })
      .finally(()=>setLoading(false))

  }
  console.log('errors', errors)
  const removeOrder = () =>{
    if(window.confirm('Вы уверены что хотите удалить Инвойс?')){
      axios.delete('api/railways/'+id)
        .then(()=>{
          navigate(RailListRoute)
        })
    }
  }
  if(loading){
    return <Loader/>
  }
  console.log('data', data)
  return (
    <>
      <Helmet>
        <title>{`Бронь ${data?.orderNumber}` || 'ЖД'}</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        {data && <RailWaisOrdersItemForm
          order={data}
          formController={formController}
          prefix={'order'}
          removeOrder={() => removeOrder()}
        />}
        <LoadingButton loading={loading} title={'Сохранить изменения'} type={'submit'}/>
      </form>
    </>

  );
};

export default RailOrderPage;

