import React, {useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import LoadingButton from "../../base/components/loadingButton";
import {Col, Row} from "reactstrap";
import _ from 'lodash'
import axios from "../../../helpers/axios";
import BadTransItem from "../components/badTransItem";

const NeedCheckList = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const formController = useForm()
  const onSubmit = (data) =>{
    setLoading(true)
    axios.post('/api/dashboard/globalCheck', data).then(({data})=>{
      setData(data)
    }).finally(()=>setLoading(false))
  }
  const groupBadTrans = data ? _.groupBy(data.badTransactions.badTransactions, 'sheet'):{}
  console.log(data, groupBadTrans)
  return (
    <>
      <Portlet>
        <PortletHeader title={''} toolbar={<PortletHeaderToolbar>
          <form onSubmit={formController.handleSubmit(onSubmit)} className={'d-flex align-items-center gap-2'}>
            <CustomDatePicker formController={formController}
                              label={"Дата c"}
                              value={moment().subtract(7, 'days').format('DD.MM.YYYY')}
                              name={`startDate`}
                              rules={{required: true}}/>
            <CustomDatePicker formController={formController}
                              label={"Дата по"}
                              value={moment().format('DD.MM.YYYY')}
                              name={`endDate`}
                              rules={{required: true}}/>
            <LoadingButton loading={loading} title={'Получить'}/>

          </form>
        </PortletHeaderToolbar>}/>
      </Portlet>
      {data && <Row>
        <Col lg={6}>
          <Portlet>
            <PortletHeader title={<div >
              <b>Неккоректные</b> (Покупатель/Услуга) <b>{data.badTransactions.totalBadCounterparty}/{data.badTransactions.totalBadServiceItem}</b>
            </div>}
                           toolbar={<PortletHeaderToolbar>
                             Всего : {data.badTransactions.totalBadServiceItem+data.badTransactions.totalBadCounterparty}
                           </PortletHeaderToolbar>}/>
              {Object.keys(groupBadTrans).map((key,i)=>{
                return <React.Fragment key={i}>
                  <BadTransItem name={key} arr={groupBadTrans[key]} type={'transactions'}/>
                </React.Fragment>
              })}
          </Portlet>
        </Col>
        <Col>
          <Portlet>
            <PortletHeader title={'Неоплаченное'}
                           toolbar={<PortletHeaderToolbar>Всего {data.unpaidOrders.totalUnpaidOrders}</PortletHeaderToolbar>}/>
            {data.unpaidOrders.unpaidOrders.map((row,i)=>{
              return <BadTransItem name={row.name} arr={row.orders} type={'unpaid'}/>
            })}
          </Portlet>
        </Col>
      </Row>}

    </>
  );
};

export default NeedCheckList;
